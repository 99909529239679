import { Customer } from './customer.model';

export class SalesPartner {
  name?: string;
  knr?: string;
  companyLogoImg?: string;
  signatureImg?: string;
  websiteUrl?: string;
  faxNr?: string;
  ustIdNr?: string;
  taxNr?: string;
  shareHolder?: string;
  commercialRegister?: string;
  registerCourt?: string;
  id?: number;
  contactDetails?: Customer;
}
