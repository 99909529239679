/**
 * BlueSolution Subscription Management API
 * Last changed: 2024-11-18T10:02:49+00:00
 *
 * OpenAPI spec version: 1.0.9
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * The new modules which are included in a specific product
 */
export interface OneqrewModule { 
    name: string;
    /**
     * Price in EUR
     */
    monthlyPrice: number;
    isExtra: boolean;
    id?: number;
}