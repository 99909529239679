import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { setCustomer, setPlan } from '@oneqrew-partnertool/core';
import { FaqsComponent } from '@oneqrew-partnertool/faqs';
import { Customer, Plan } from '@oneqrew-partnertool/models';
import { ModuleAddonsComponent } from '@oneqrew-partnertool/module-addons';
import { PlanUpgradeComponent } from '@oneqrew-partnertool/plan-upgrade';
import { SubscriptionPlansComponent } from '@oneqrew-partnertool/subscription-plans';
import { handlePlanCheck } from '@oneqrew-partnertool/utils';
@Component({
  selector: 'app-front-page',
  standalone: true,
  imports: [CommonModule, FaqsComponent, ModuleAddonsComponent, PlanUpgradeComponent, SubscriptionPlansComponent],
  templateUrl: './front-page.component.html'
})
export class FrontPageComponent implements OnInit {
  pageTitle: string = 'Umstellung shp - Abo-Vergleich';
  @Input() isSubPage = false;
  loading = true;
  plans: Plan[] = [];
  customer!: Customer;

  constructor(private store: Store, private titleService: Title, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.pageTitle);

    this.route.data.subscribe((customer: { [key: string]: any }) => {
      this.customer = customer['customer'];

      const updatedCustomer = {
        ...this.customer
      };

      this.store.dispatch(setCustomer(updatedCustomer));
    });

    this.route.data.subscribe((data) => {
      this.plans = data['plans'];

      handlePlanCheck(
        this.store,
        (plan) => {},
        () => {
          this.store.dispatch(setPlan(this.plans[0]));
        }
      );
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    $event.returnValue = 'Beim erneuten Laden der Seite gehen die ausgewählten Module und der Plan verloren.';
  }
}
