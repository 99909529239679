/**
 * BlueSolution Subscription Management API
 * Last changed: 2025-03-05T10:54:50+00:00
 *
 * OpenAPI spec version: 1.0.13
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomerCustomer } from './customerCustomer';
import { OneqrewModule } from './oneqrewModule';
import { OneqrewProductPackage } from './oneqrewProductPackage';
import { SubscriptionOrderContract } from './subscriptionOrderContract';

export interface CustomerOneqrewSubscription { 
    /**
     * Sum of all costs in EUR
     */
    monthlyCostSum?: number;
    /**
     * Amount of mobile devices the customer is paying for in their OneQrew subscription
     */
    amountDevices: number;
    /**
     * Amount of workspaces the customer is paying for in their OneQrew subscription
     */
    amountWorkspaces: number;
    packageType?: string;
    customerUuid: string;
    orderedAt: string;
    endOfContract: string;
    startOfContract: string;
    isCloudVersion: boolean;
    amountClients: number;
    id?: number;
    orderContract?: SubscriptionOrderContract;
    customer?: Array<CustomerCustomer>;
    extraModules?: OneqrewModule;
    productPackage?: Array<OneqrewProductPackage>;
}