import { LegacyModule } from './legacy-module';
import { SalesPartner } from './sales-partner.model';

export class Customer {
  id!: number;
  knr!: string;
  customerCode!: string;
  bsNumber!: string;
  contactId!: number;
  salutation?: string;
  salutationOpener?: string;
  firstName!: string;
  lastName!: string;
  email!: string;
  confirmEmail!: string;
  company?: string;
  street!: string;
  phoneNr?: string;
  mobilePhoneNr?: string;
  addressTwo!: string;
  postCode!: string;
  city!: string;
  country?: string;
  cardHolder!: string;
  iban!: string;
  bic!: string;
  creditInstitution!: string;
  legacyProductName!: string;
  functionModules!: LegacyModule[];
  modules!: LegacyModule[];
  salesPartner!: SalesPartner;
  contractEndsAt?: string;
  contractRenewal?: string;
  companyCeo?: string;
  companyName?: string;
  ustIdNr!: string
}
