/**
 * BlueSolution Subscription Management API
 * Last changed: 2025-03-05T10:54:50+00:00
 *
 * OpenAPI spec version: 1.0.13
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OneqrewModule } from './oneqrewModule';
import { OneqrewProductPackage } from './oneqrewProductPackage';

export interface OneqrewPackageModules { 
    id?: number;
    module?: Array<OneqrewModule>;
    productPackage?: Array<OneqrewProductPackage>;
}