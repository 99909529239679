import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PlanDataService } from '@oneqrew-partnertool/utils';
import { setFetchedPlans } from '@oneqrew-partnertool/core';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
@Injectable({ providedIn: 'root' })
export class InitailPlansResolver implements Resolve<any> {
  constructor(private planDataService: PlanDataService, private store: Store) {}

  resolve(): Observable<any> {
    return this.planDataService.getInitialPlans().pipe(
      tap((fetchedPlans) => {
        this.store.dispatch(setFetchedPlans({ plans: fetchedPlans }));
      }),
      map((fetchedPlans) => fetchedPlans)
    );
  }
}
