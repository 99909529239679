/**
 * BlueSolution Subscription Management API
 * Last changed: 2024-11-18T10:02:49+00:00
 *
 * OpenAPI spec version: 1.0.9
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomerContactDetails } from './customerContactDetails';

export interface CustomerSalesPartner { 
    name: string;
    knr: string;
    /**
     * image as base64
     */
    companyLogoImg?: string;
    signatureImg?: string;
    websiteUrl?: string;
    faxNr?: string;
    ustIdNr?: string;
    taxNr?: string;
    shareHolder?: string;
    commercialRegister?: string;
    registerCourt?: string;
    id?: number;
    contactDetails?: CustomerContactDetails;
}