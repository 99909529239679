
<section class="{{ class }}">
  <lib-container>
    <div class="py-6">
      <h2 class="my-5">
        Effiziente Verwaltung
        <span class="text-header-underlined">jetzt im Abo!</span>
      </h2>
    </div>
    <ng-container *ngIf="isInitialized; else loading">
      <lib-new-plan-selector
        [company]="frontPageTxt.newCustomer['header'].company"
        [tag]="frontPageTxt.planUpgrade['header'].tag"
        [plan]="(plan$ | async)!"
        [disclaimer]="
          frontPageTxt.planUpgrade['disclaimer']
        "
        [isSubscriptionPlans]="false"
        [customer]="(customer$ | async)!"
      ></lib-new-plan-selector>
    </ng-container>

    <ng-template #loading>
      <h4>
        Bitte warten...
        <i class="pi pi-spin pi-spinner"></i>
      </h4>
    </ng-template>
  </lib-container>
</section>
