<lib-container>
  <div class="auth py-8">
    <div class="card flex flex-column justify-content-center">
      <h4 class="mb-4">
        <label for="username">Customer Code</label>
      </h4>

      <div class="flex gap-2 input">
        <div class="w-full">
          <p-inputMask
            inputId="username"
            [(ngModel)]="customerCode"
            mask="****-****-****-****"
            autoClear="false"
            unmask="true"
            aria-describedby="username-help"
            (keydown.enter)="handleClick()"
          ></p-inputMask>

          <span *ngIf="codeError" class="small-text text-dark-orange block">
            Kundencode kann nicht gefunden werden. Bitte überprüfen Sie Ihren Code und versuchen Sie es erneut.
          </span>

          <span *ngIf="codeIncomplete" class="small-text text-dark-orange block">
            Der Kundencode muss genau 16 Zeichen lang sein (ohne Bindestriche).
          </span>
        </div>

        <div class="button">
          <div *ngIf="loading" class="loading">
            <i class="pi pi-spin pi-spinner"></i>
          </div>
          <p-button (click)="handleClick()" icon="pi pi-arrow-right" iconPos="right" label="Submit"></p-button>
        </div>
      </div>
    </div>
  </div>
</lib-container>
