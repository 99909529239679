import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { Plan } from '@oneqrew-partnertool/models';  
import { selectPlan } from '@oneqrew-partnertool/core';

/**
 * Reusable function to check if a plan exists in the store and execute corresponding functions.
 * @param store - The NgRx store instance.
 * @param ifFunction - A callback function to execute if the plan exists.
 * @param elseFunction - A callback function to execute if the plan does not exist.
 */
export function handlePlanCheck(
  store: Store<any>, 
  ifFunction: (existingPlan: Plan) => void, 
  elseFunction: () => void
): void {
  store
    .select(selectPlan)
    .pipe(
      take(1),
      tap((existingPlan: Plan | null) => {
        if (existingPlan?.planType !== undefined) {
          ifFunction(existingPlan);  
        } else {
          elseFunction(); 
        }
      })
    )
    .subscribe();
}
