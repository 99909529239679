<div [ngClass]="class">
  <button [ngClass]="class + '__button'" (click)="toggleAccordion()" onKeyPress="" onKeyDown="" onKeyUp="">
    <h4 class="text-left text-dark-grey">{{ title }}</h4>

    <img
      src="assets/img/icons/chevron-down.svg"
      alt="Chevron Down"
      width="32"
      height="32"
      class="{{ class }}__icon"
      [ngClass]="isExpanded ? 'open' : 'close'"
    />
  </button>
  <div class="accordion__content" *ngIf="isExpanded">
    <ng-content></ng-content>
  </div>
</div>
