import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BankDetailsComponent } from '@oneqrew-partnertool/bank-details';
import { APP_CONFIG, AppConfig } from '@oneqrew-partnertool/config';
import { selectCustomer, selectPlan, setCustomer } from '@oneqrew-partnertool/core';
import {
  ContactDetails,
  Customer,
  CustomerPayload,
  ExtraModule,
  OneqrewSubscription,
  Plan,
  ProductPackage,
  SalesPartnerPayload,
  Sepa,
  UpdateSubscription
} from '@oneqrew-partnertool/models';
import { OrderContactComponent } from '@oneqrew-partnertool/order-contact';
import { OrderOverviewComponent } from '@oneqrew-partnertool/order-overview';
import { handlePlanCheck, PageGuard, RouteParam, SharedModule } from '@oneqrew-partnertool/utils';
import { catchError, Observable, throwError, finalize, tap, retry, timer, timeout } from 'rxjs';
@Component({
  selector: 'app-checkout-page',
  standalone: true,
  imports: [OrderOverviewComponent, OrderContactComponent, BankDetailsComponent, SharedModule],
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent implements OnInit {
  checkoutForm!: FormGroup;
  customer!: Customer;
  userID = '';
  plan!: Plan;
  errorSending = false;
  loading = false;
  isNewCustomer = false;

  constructor(
    private store: Store,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private pageGuard: PageGuard
  ) {}

  ngOnInit(): void {
    this.store.select(selectCustomer).subscribe((customer) => {
      this.customer = customer;
    });

    this.store.select(selectPlan).subscribe((plan) => {
      this.plan = plan;
    });

    this.route.data.subscribe((data: { [key: string]: any }) => {
      this.isNewCustomer = data['isNewCustomer'];
    })

    handlePlanCheck(
      this.store,
      () => {
        this.checkoutForm = this.fb.group({});
        this.userID = RouteParam.getParamAsString(this.route, 'id');
      },
      () => {
        sessionStorage.clear();
        this.router.navigate(['/']);
      }
    );
  }

  onFormSubmit() {
    if (this.checkoutForm.valid) {
      const updatedCustomer = {
        ...this.customer,
        firstName: this.checkoutForm.value.firstName,
        lastName: this.checkoutForm.value.lastName,
        email: this.checkoutForm.value.email,
        confirmEmail: this.checkoutForm.value.confirmEmail,
        company: this.checkoutForm.value.company,
        street: this.checkoutForm.value.street,
        addressTwo: this.checkoutForm.value.addressTwo,
        postCode: this.checkoutForm.value.postCode,
        city: this.checkoutForm.value.city
      };

      this.store.dispatch(setCustomer(updatedCustomer));

      this.loading = true;

      // API Post Request Map
      const contactDetails: ContactDetails = {
        id: this.customer.contactId,
        bsNumber: this.customer.bsNumber,
        salutation: this.customer.salutation,
        salutationOpener: this.customer.salutationOpener,
        firstName: this.checkoutForm.value.firstName,
        lastName: this.checkoutForm.value.lastName,
        phoneNr: String(this.customer.phoneNr) || null,
        mobilePhoneNr: this.customer.mobilePhoneNr || null,
        email: this.checkoutForm.value.email,
        companyName: this.customer.company,
        street: this.checkoutForm.value.street,
        addressExtra: this.checkoutForm.value.addressTwo || null,
        postalCode: this.checkoutForm.value.postCode,
        city: this.checkoutForm.value.city,
        ustIdNr: this.customer.ustIdNr,
      };

      const salesPartner: SalesPartnerPayload = {
        ...this.customer.salesPartner,
        contactDetails: this.customer.salesPartner.contactDetails
      };

      const customer: CustomerPayload = {
        id: this.customer.id,
        knr: this.customer.knr,
        legacyProductName: this.customer.legacyProductName,
        hasAcceptedAgb: true,
        hasAcceptedPrivacyAgreement: true,
        contactDetails,
        salesPartner: salesPartner.id ? salesPartner : undefined
      };

      const mappedModules: ExtraModule[] =
        this.plan?.additionalModules?.map((module) => ({
          id: module.id,
          name: module.name,
          monthlyPrice: module.price,
          isExtra: true
        })) || [];

      const mappedPackage: ProductPackage[] = [
        {
          name: this.plan.packageType === 'pro-l' ? 'Pro L' : 'Pro M',
          monthlyPrice: this.plan.price,
          type: this.plan.packageType
        }
      ];

      const startDate = new Date(this.plan.startDate);
      const startDateYear = startDate.getFullYear();
      const startDateMonth = String(startDate.getMonth() + 1).padStart(2, '0');
      const startDateDay = String(startDate.getDate()).padStart(2, '0');
      const formattedStartDate = `${startDateYear}-${startDateMonth}-${startDateDay}`;

      const orderDate = new Date();
      const orderDateYear = orderDate.getFullYear();
      const orderDateMonth = String(orderDate.getMonth() + 1).padStart(2, '0');
      const orderDateDay = String(orderDate.getDate()).padStart(2, '0');
      const formattedOrderDate = `${orderDateYear}-${orderDateMonth}-${orderDateDay}`;

      const oneqrewsubscription: OneqrewSubscription = {
        monthlyCostSum: this.plan.monthlyCostSum || 0,
        orderedAt: formattedOrderDate + 'T00:00:00Z',
        endOfContract: this.customer.contractEndsAt ?? '',
        startOfContract: formattedStartDate + 'T00:00:00Z',
        amountDevices: this.plan.devicesCount,
        amountWorkspaces: this.plan.workStationCount,
        amountClients: this.plan.clientsCount,
        isCloudVersion: this.plan.cloudVersion,
        extraModules: mappedModules,
        productPackage: mappedPackage[0],
        customer: this.customer
      };

      const sepa: Sepa = {
        iban: this.checkoutForm.value.iban,
        bic: this.checkoutForm.value.bic,
        creditInstitution: this.checkoutForm.value.creditInstitution,
        accountOwner: this.checkoutForm.value.cardHolder
      };

      const payload = {
        id: this.customer.id,
        contactdetails: contactDetails,
        customer,
        oneqrewsubscription,
        sepa
      };

      const MAX_TIMEOUT = 30000;

      this.loading = true;
      this.errorSending = false;

      this.submitSubscription(payload)
        .pipe(
          timeout({
            each: MAX_TIMEOUT,
            with: () => {
              console.error('Request timed out after 30 seconds.');
              return throwError(() => new Error('Request timed out. Please try again.'));
            }
          }),
          tap({
            error: (error) => {
              console.warn(`Retrying request... (${error.message})`);
            }
          }),
          retry({
            count: 3,
            delay: (error, retryCount) => {
              const delayTime = Math.pow(2, retryCount) * 1000;
              console.warn(`Retry #${retryCount} happening in ${delayTime / 1000} seconds...`);
              return timer(delayTime);
            }
          }),
          finalize(() => (this.loading = false))
        )
        .subscribe({
          next: () => {
            this.pageGuard.setNavigatedFromValidUrl(true);
            if (this.isNewCustomer) {
              this.router.navigate(['/bestellung/success']);
            } else {
              this.router.navigate(['/umstellung/success/', this.userID]);
            }
          },
          error: (error) => {
            this.errorSending = true;
            console.error('Final Error after retries:', error);
          }
        });
    } else {
      this.checkoutForm.markAllAsTouched();
      console.error('Form is invalid');
    }
  }

  submitSubscription(updateSubscription: UpdateSubscription): Observable<UpdateSubscription> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };

    const requestUrl = this.isNewCustomer ? `${this.config.landingPage.apiURL}/subscription-order/`
      : `${this.config.landingPage.apiURL}/subscription-order/${this.userID}/`;

    return this.http
      .post<UpdateSubscription>(requestUrl, updateSubscription, httpOptions)
      .pipe(catchError((error) => throwError(() => error)));
  }

  formInitialized(form: FormGroup) {
    for (const proprty in form.controls) {
      this.checkoutForm.setControl(proprty, form.controls[proprty]);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    $event.returnValue = 'Beim erneuten Laden der Seite gehen die ausgewählten Module und der Plan verloren.';
  }
}
