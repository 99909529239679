<button
  class="{{ class }} text-left"
  [ngClass]="{ 'active text-white': isActive }"
  (click)="onToggleActive()"
  (keydown)="onToggleActive()"
  [disabled]="disabled"
>
  <div [ngClass]="class + '__header'">
    <div class="{{ class + '__icon-wrap' }}">
      <img
        [src]="'assets/img/icons/' + (isActive ? 'orange-check-icon.svg' : 'add-icon.svg')"
        [alt]="isActive ? 'Check icon' : 'Add icon'"
        width="52"
        height="52"
        class="{{ class + '__icon' }}"
        [ngClass]="{ active: isActive }"
      />
    </div>

    <span *ngIf="tag" [ngClass]="class + '__tag small-text'">{{ tag }}</span>
  </div>

  <p class="{{ class + '__title' }}" [ngClass]="{ 'text-white': isActive }">
    {{ name }}
  </p>

  <p class="{{ class + '__price h4' }}" [ngClass]="{ 'text-white': isActive }">+ {{ pricePerMonth }}€ / Monat</p>
</button>
