<div [ngClass]="class + ' ' + spacer">
  <lib-container>
    <h3 [ngClass]="class + '__title text-center'">
      {{ faqsData['mainTitle'] }}
    </h3>

    <div [ngClass]="class + '__items'">
      <ng-container *ngFor="let faq of faqsData['faqs']">
        <lib-accordion [title]="faq.title">
          <p>{{ faq.content }}</p>
        </lib-accordion>
      </ng-container>
    </div>
  </lib-container>
</div>
