import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {

  transform(count: number, itemType: string): string {
    switch(itemType) {
      case 'device':
        if (count === 1) return ` zusätzliches mobiles Gerät`;
        return ` zusätzliche mobile Geräte`;
      case 'workStation':
        if (count === 1) return ` zusätzlicher Arbeitsplatz`;
        return ` zusätzliche Arbeitsplätze`;
      case 'client':
        if (count === 1) return ` weiterer Mandant`;
        return ` weitere Mandanten`;
      default:
        return '';
    }
  }
}
