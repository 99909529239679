import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PlanDataService } from '@oneqrew-partnertool/utils';
import { selectCustomer, selectPlan } from '@oneqrew-partnertool/core';
import { Customer, Plan } from '@oneqrew-partnertool/models';
import * as planUpgradeData from '../../../../../shared-assets/data/pages/front-page.json';
import { ContactCtaComponent } from '@oneqrew-partnertool/contact-cta';
import { ContainerComponent } from '@oneqrew-partnertool/container';
import { PlanSelectorComponent } from '@oneqrew-partnertool/plan-selector';
import { PromotionBannerComponent } from '@oneqrew-partnertool/promotion-banner';
import { SubscriptionOverviewComponent } from '@oneqrew-partnertool/subscription-overview';
import { SharedModule } from '@oneqrew-partnertool/utils';

@Component({
  selector: 'lib-plan-upgrade',
  standalone: true,
  imports: [
    SharedModule,
    ContainerComponent,
    PromotionBannerComponent,
    PlanSelectorComponent,
    SubscriptionOverviewComponent,
    ContactCtaComponent
  ],
  templateUrl: './plan-upgrade.component.html',
  styleUrls: ['./plan-upgrade.component.scss']
})
export class PlanUpgradeComponent implements OnInit {
  class: string = 'plan-upgrade';
  planUpgradeData = planUpgradeData;
  plans: Plan[] = [];
  plan$!: Observable<Plan>;
  customer$!: Observable<Customer>;
  isInitialized = false;

  constructor(private store: Store, private planDataService: PlanDataService) {}

  ngOnInit(): void {
    // Check sessionStorage for 'planSet' flag
    const isPlanSet = sessionStorage.getItem('planSet');
    this.customer$ = this.store.select(selectCustomer).pipe(filter((customer) => !!customer));

    this.plan$ = this.store.select(selectPlan);
    this.isInitialized = true;
  }
}
