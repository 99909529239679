import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContainerComponent } from '@oneqrew-partnertool/container';
import { selectPlan, selectFetchedPlans } from '@oneqrew-partnertool/core';
import { take } from 'rxjs/operators';
import { Plan } from '@oneqrew-partnertool/models';
import { PlanSelectorComponent } from '@oneqrew-partnertool/plan-selector';
import { SharedModule } from '@oneqrew-partnertool/utils';
import { Observable } from 'rxjs';
import * as textData from '../../../../../shared-assets/data/pages/front-page.json';

@Component({
  selector: 'lib-subscription-plans',
  standalone: true,
  imports: [SharedModule, ContainerComponent, PlanSelectorComponent],
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss']
})
export class SubscriptionPlansComponent implements OnInit {
  class = 'subscription-plans';
  plans: Plan[] = [];
  plan$!: Observable<Plan>;
  textData = textData;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectFetchedPlans)
      .pipe(take(1))
      .subscribe((fetchedPlans) => {
        if (!fetchedPlans || fetchedPlans.length === 0) {
          console.warn('Fetched Plans are empty. Ensure the store is updated before this component initializes.');
        } else {
          this.plans = fetchedPlans;
        }
      });

    this.plan$ = this.store.select(selectPlan);
  }
}
