<div
  class="{{ class }}"
  #target
  [ngClass]="{
    'plan-subscriptions': isSubscriptionPlans,
    'not-plan-subscriptions': !isSubscriptionPlans,
    light: !isSelectedPlan && isSubscriptionPlans,
    selected: isSelectedPlan
  }"
>
  <div class="flex justify-content-center head-container">
    <div *ngIf="company" [ngClass]="class + '__company'">
      <lib-tag [title]="company"></lib-tag>
    </div>
  </div>

  <div [ngClass]="class + '__header'">
    <p class="h3 text-primary-color">
      {{ plan.planType }}
    </p>
    <span class="block mb-4 text-dark-grey small-text">
      {{ frontPage.planSelector.introText }}
      <a href="{{ frontPage.planSelector.introLink }}" class="text-underlined" target="_blank">{{ frontPage.planSelector.introLink }}</a>
    </span>
    <div>
      <ng-container *ngIf="!isSubscriptionPlans; else headingH2">
        <div class="flex align-items-center">
          <h2 class="text-primary-color">{{ planCalculatorService.getSubtotal(plan) | euroCurrency }}</h2>
          <div class="flex-column">
            <p *ngIf="cloudVersion" class="text-bold ml-2">{{ frontPage.planSelector.addCloudVersion }}</p>
            <p *ngIf="devicesCount !== 0" class="text-bold ml-2">
              + {{ planCalculatorService.getTotalDeviceCost(devicesCount) | euroCurrency }} {{ devicesCount }}
              {{ (devicesCount | pluralize : 'device').replace('zusätzliche', '').replace(' s', '') }}
            </p>
          </div>
        </div>
      </ng-container>
      <ng-template #headingH2>
        <h2 class="text-dark-grey">
          {{ planCalculatorService.getSubtotal(plan) | euroCurrency }}
        </h2>
      </ng-template>
    </div>
    <p class="text-dark-grey mb-2">{{ frontPage.planSelector.perMonth }}</p>

    <label *ngIf="!isSubscriptionPlans" for="buttondisplay" class="text-bold required">
      {{ frontPage.planSelector.calendar.changeDateText.newCustomer }}
    </label>
    <div class="{{ class + '__input flex mt-2' }}">
      <p-calendar
        #calendar
        *ngIf="!isSubscriptionPlans"
        [(ngModel)]="startDate"
        [showIcon]="true"
        [showOnFocus]="false"
        inputId="buttondisplay"
        dateFormat="dd.mm.yy"
        (input)="formatInputDate($event)"
        [minDate]="minDate"
        (onSelect)="handleDateSelect($event)"
        [placeholder]="frontPage.planSelector.calendar.placeHolder"
      >
        <ng-template pTemplate="footer">
          <div class="{{ class + '__calendar-footer' }}">
            <button type="button" (click)="setCurrentDate()">{{ frontPage.planSelector.calendar.todayButton }}</button>
          </div>
        </ng-template>
      </p-calendar>

      <p-button
        [label]="buttonLabel"
        [icon]="isSelectedPlan ? 'pi pi-check' : 'pi pi-arrow-right'"
        iconPos="right"
        [class]="isSelectedPlan ? 'p-button-outlined' : 'p-text-normal'"
        [disabled]="this.isSubscriptionPlans ? isSelectedPlan : false"
        (onClick)="handleButtonClick(plan)"
      ></p-button>
    </div>

    <p class="small-text text-red mt-1" *ngIf="startDateError && !isSubscriptionPlans">{{ frontPage.planSelector.calendar.noStartDate }}</p>
    <p class="small-text text-red mt-1" *ngIf="noResetDate && !isSubscriptionPlans">{{ frontPage.planSelector.calendar.noReset }}</p>
  </div>

  <div class="mt-4">
    <p class="mb-3 text-bold">{{ frontPage.planSelector.featuresHeader }}</p>
    <div class="mb-3" [ngClass]="{ 'top-input': !isSubscriptionPlans }">
      <div>
        <div class="{{ class + '__list-item' }}">
          <lib-list-item [text]="'Grundmodul ' + (plan.planType | basicModuleText)"></lib-list-item>
        </div>
      </div>
      <ng-container *ngIf="!isSubscriptionPlans">
        <div class="flex align-items-center relative">
          <div class="pt-2">
            <p-inputSwitch [(ngModel)]="cloudVersion" (ngModelChange)="handleCloudVersionChange($event)" [disabled]="isSubscriptionPlans" />
          </div>
          <span class="ml-2 block">{{ frontPage.planSelector.cloudVersion.label }}</span>
        </div>
      </ng-container>
    </div>

    <div class="{{ class + '__list' }}">
      <div *ngIf="!isSubscriptionPlans" class="{{ class + '__increment-decrement' }}">
        <div class="increment-decrement-container">
          <p-inputNumber
            inputId="device"
            [(ngModel)]="devicesCount"
            [showButtons]="true"
            [min]="0"
            buttonLayout="horizontal"
            spinnerMode="horizontal"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            currency="EUR"
            (ngModelChange)="handleDevicesCountChange($event)"
            [disabled]="isSubscriptionPlans"
          ></p-inputNumber>
        </div>

        <label for="device">
          {{ devicesCount | pluralize : 'device' }} {{ frontPage.planSelector.mobileDevices.intro }} {{ 4.9 | euroCurrency }}
          {{ frontPage.planSelector.mobileDevices.outro }}
        </label>
      </div>

      <div *ngIf="!isSubscriptionPlans" class="{{ class + '__increment-decrement' }}">
        <div class="increment-decrement-container">
          <p-inputNumber
            inputId="client"
            [(ngModel)]="clientsCount"
            [showButtons]="true"
            [min]="0"
            buttonLayout="horizontal"
            spinnerMode="horizontal"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            (ngModelChange)="handleClientsCountChange($event)"
            currency="EUR"
            [disabled]="isSubscriptionPlans"
          ></p-inputNumber>
        </div>

        <label for="client">
          {{ clientsCount | pluralize : 'client' }} {{ frontPage.planSelector.clients.intro }} {{ 20 | euroCurrency }}
          {{ frontPage.planSelector.clients.outro }}
        </label>
      </div>

      <div *ngIf="!isSubscriptionPlans" class="{{ class + '__increment-decrement mb-3' }}">
        <div class="increment-decrement-container">
          <p-inputNumber
            inputId="workStation"
            [ngModel]="workStationCount"
            [showButtons]="true"
            [min]="0"
            buttonLayout="horizontal"
            spinnerMode="horizontal"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            currency="EUR"
            (ngModelChange)="handleWorkStationCountChange($event)"
            [disabled]="isSubscriptionPlans"
          ></p-inputNumber>
        </div>

        <label for="workStation">
          {{ workStationCount | pluralize : 'workStation' }} {{ frontPage.planSelector.workStation.intro }}
          {{ planCalculatorService.getTotalWorkStationCostDisplay(plan) | euroCurrency }} {{ frontPage.planSelector.workStation.outro }}
        </label>
      </div>
    </div>

    <ul *ngIf="plan.modules" class="{{ class + '__list  mb-3 ' }}">
      <li class="{{ class + '__list-item' }}" *ngFor="let item of plan.modules">
        <lib-list-item [text]="item.name" icon="orange-check" [icon]="isSubscriptionPlans ? 'check' : 'orange-check'"></lib-list-item>
      </li>
      <ng-container *ngIf="plan.planType === 'smarthandwerk pro L-Paket'">
        <li class="{{ class + '__list-item' }}" *ngFor="let item of plan.additionalModules | slice : 0 : disableModules.length">
          <lib-list-item [text]="item.name" icon="orange-check" [icon]="'orange-check'"></lib-list-item>
        </li>
      </ng-container>
    </ul>

    <div class="mt-4 mb-3">
      <p
        *ngIf="(plan.additionalModules || []).length > (plan.planType === 'smarthandwerk pro L-Paket' ? disableModules.length : 0)"
        class="text-bold mb-2"
      >
        {{ frontPage.planSelector.modulesHeader }}
      </p>

      <ul *ngIf="plan.additionalModules" class="{{ class + '__list  mb-3' }}">
        <ng-container *ngFor="let item of plan.additionalModules; let i = index">
          <li *ngIf="plan.planType === 'smarthandwerk pro L-Paket' && i >= disableModules.length" class="{{ class + '__list-item' }}">
            <lib-list-item
              [text]="item.name"
              icon="plus"
              [button]="true"
              (action)="handleListItemAction(item.id, item.name, item.price)"
            ></lib-list-item>
          </li>

          <li *ngIf="plan.planType === 'smarthandwerk pro M-Paket'" class="{{ class + '__list-item' }}">
            <lib-list-item
              [text]="item.name"
              icon="plus"
              [button]="true"
              (action)="handleListItemAction(item.id, item.name, item.price)"
            ></lib-list-item>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
