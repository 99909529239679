import { Inject, Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { PrimeNGConfig } from 'primeng/api';
import { APP_CONFIG, AppConfig } from '@oneqrew-partnertool/config';
@Component({
  selector: 'app-root',

  templateUrl: './app.component.html'
})
export class AppComponent {
  isSubPage = false;
  customerData: any;
  loading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(APP_CONFIG) private config: AppConfig,
    private primengConfig: PrimeNGConfig
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.root),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        map((route) => route.snapshot.data)
      )
      .subscribe((data) => {
        this.isSubPage = data['isSubPage'] || false;
      });

    if (this.config.landingPage.cookieYes) {
      this.loadCookieYesScript();
    }
  }

  ngOnInit() {
    this.primengConfig.setTranslation({
      accept: 'Akzeptieren',
      reject: 'Abbrechen',
      dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
      today: 'Heute',
      clear: 'Löschen',
      dateFormat: 'dd.mm.yy',
      weekHeader: 'Woche',
      firstDayOfWeek: 1
    });
  }

  loadCookieYesScript() {
    const script = document.createElement('script');
    script.id = 'cookieyes';
    script.type = 'text/javascript';
    script.src = 'https://cdn-cookieyes.com/client_data/4863d00aad04bc18c560293c/script.js';
    document.head.appendChild(script);
  }
}
