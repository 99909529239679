import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatterService {
  constructor() {}

  formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits for day
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits for month
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of year

    return `${day}.${month}.${year}`;
  }
}
