/**
 * BlueSolution Subscription Management API
 * Last changed: 2024-11-18T10:02:49+00:00
 *
 * OpenAPI spec version: 1.0.9
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Modules a part of products and descibe. A product can have many modules
 */
export interface BsslegacyLegacyModule { 
    /**
     * The name of this module
     */
    name: string;
    /**
     * The article number of this module
     */
    moduleNumber: string;
    /**
     * Module is either a &#039;System&#039; or &#039;Extras/Module&#039;. &#039;System&#039; means isFunction is true.
     */
    isFunction: boolean;
    /**
     * This is the &#039;Modultyp&#039; field from their DB. 0 if &#039;YesNo&#039;, 1 if &#039;Counter&#039; Counter means a customer can have multiples of this product. YesNo means it can only be on or off.
     */
    canHaveMultiple: boolean;
    articleNumber: string;
    type: number;
    id?: number;
}