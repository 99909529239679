import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectCustomer } from '@oneqrew-partnertool/core';
import { Customer } from '@oneqrew-partnertool/models';
import { PrimeNgModule } from '@oneqrew-partnertool/primeng';
import { TagComponent } from '@oneqrew-partnertool/tag';
import { SharedModule } from '@oneqrew-partnertool/utils';
import { take } from 'rxjs/operators';
import * as textData from '../../../../../../common/shared-assets/data/pages/order-page.json';

@Component({
  selector: 'lib-order-contact',
  standalone: true,
  imports: [SharedModule, PrimeNgModule, TagComponent],
  templateUrl: './order-contact.component.html',
  styleUrls: ['./order-contact.component.scss']
})
export class OrderContactComponent implements OnInit {
  @Input() tag: boolean = false;
  @Input() tagSize: string = '';
  @Input() isNewCustomer = false;
  @Input() isCheckoutPage = false;
  @Output() contactForm$ = new EventEmitter<FormGroup>();
  class = 'order-contact';
  contactForm!: FormGroup;
  fieldLabelData: { [key: string]: string } = {};
  fields: Array<{ name: string; label: string; colSize: string; isRequired: boolean }> = [];
  textData = textData;
  salutation = '';
  salutationOpener = '';
  salutationSelect: { label: string; value: string }[] = [];

  constructor(private fb: FormBuilder, private store: Store) {
    this.contactForm = this.fb.group({
      salutation: ['']
    });
  }

  ngOnInit(): void {
    this.store
      .select(selectCustomer)
      .pipe(take(1))
      .subscribe((storedCustomer: Customer) => {
        this.initializeForm(storedCustomer);
      });

    this.contactForm$.emit(this.contactForm);

    this.salutationSelect = [
      { label: 'Herr', value: 'Herr' },
      { label: 'Frau', value: 'Frau' },
      { label: 'Ohne', value: 'Ohne '}
    ];

    this.contactForm.get('salutation')?.valueChanges.subscribe((value) => {
      this.updateSalutationOpener(value);
    });
    this.contactForm.get('firstName')?.valueChanges.subscribe((value) => {
      this.updateSalutationOpener(value);
    });
    this.contactForm.get('lastName')?.valueChanges.subscribe((value) => {
      this.updateSalutationOpener(value);
    });
  }

  private initializeForm(storedCustomer: Customer): void {
    const salutationOpener = this.generateSalutationOpener(storedCustomer.salutation || '');
    const initialFormGroup = {
      salutation: [storedCustomer.salutation, [Validators.required, Validators.minLength(2)]],
      salutationOpener: [salutationOpener, [Validators.minLength(2)]],
      firstName: [storedCustomer.firstName, [Validators.required, Validators.minLength(2)]],
      lastName: [storedCustomer.lastName, [Validators.required, Validators.minLength(2)]],
      email: [storedCustomer.email, [Validators.required, Validators.email]],
      confirmEmail: [storedCustomer.confirmEmail, [Validators.required, Validators.email, this.emailMatchValidator.bind(this)]],
      company: [storedCustomer.company, Validators.minLength(2)],
      street: [storedCustomer.street, [Validators.required, Validators.minLength(2)]],
      addressTwo: [storedCustomer.addressTwo, Validators.minLength(2)],
      postCode: [storedCustomer.postCode, [Validators.required, Validators.minLength(2)]],
      city: [storedCustomer.city, [Validators.required, Validators.minLength(2)]]
    }
    this.contactForm = this.fb.group(this.isNewCustomer ?
      {
        ...initialFormGroup,
        phoneNr: [storedCustomer.phoneNr, [Validators.required, Validators.minLength(2)]],
        mobilePhoneNr: [storedCustomer.mobilePhoneNr, [Validators.pattern('^[+]?[0-9]*$')]],
        ustIdNr: [storedCustomer.ustIdNr, [Validators.required, Validators.pattern(/^(DE\d{9}|ATU\d{8}|LU\d{8})$/i)]]
      } : { ...initialFormGroup });
  }

  private updateSalutationOpener(salutation: string): void {
    const salutationOpener = this.generateSalutationOpener(salutation);

    this.contactForm.get('salutationOpener')?.setValue(salutationOpener, { emitEvent: false });
  }

  private generateSalutationOpener(salutation: string): string {
    switch (salutation) {
      case 'Herr':
        return 'Sehr geehrter Herr';
      case 'Frau':
        return 'Sehr geehrte Frau';
      default:
        return 'Guten Tag ';
    }
  }

  // Custom validator to check if confirmEmail matches email
  emailMatchValidator(control: AbstractControl) {
    const email = this.contactForm?.get('email')?.value;
    const confirmEmail = control.value;
    return email && confirmEmail && email !== confirmEmail ? { emailMismatch: true } : null;
  }

  getErrorMessage(field: string, label: string): string {
    const control = this.contactForm.get(field);
    if (control?.hasError('required')) {
      return `Bitte ${label} eingeben.`;
    }
    
    if (control?.hasError('pattern')) {
      return `Ungültige ${label}.`;
    }

    if (control?.hasError('email')) {
      return `Korrekte E-Mail-Adresse eingeben.`;
    }

    if (field === 'confirmEmail' && control?.hasError('emailMismatch')) {
      return `E-Mail-Adressen stimmen nicht überein.`;
    }

    if (control?.hasError('minlength')) {
      return `${label} must be at least 2 characters long.`;
    }

    return '';
  }
}
