import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ButtonComponent } from '@oneqrew-partnertool/button';
import { selectCustomer } from '@oneqrew-partnertool/core';
import { Customer } from '@oneqrew-partnertool/models';
import { PrimeNgModule } from '@oneqrew-partnertool/primeng';
import { PageGuard, SharedModule } from '@oneqrew-partnertool/utils';
import { take } from 'rxjs/operators';
import * as fieldLabelData from './field-label.json';

@Component({
  selector: 'lib-bank-details',
  standalone: true,
  imports: [CommonModule, PrimeNgModule, ButtonComponent, SharedModule],
  templateUrl: './bank-details.component.html',
  styleUrl: './bank-details.component.scss'
})
export class BankDetailsComponent implements OnInit {
  @Input() loading = false;
  @Output() onSubmit = new EventEmitter<void>();
  @Output() bankDetailsForm = new EventEmitter<FormGroup>();
  class = 'bank-details';
  iban: string = '';
  bankDetailsFormGroup!: FormGroup;
  customer!: Customer;
  fieldLabelData = fieldLabelData;

  constructor(private router: Router, private fb: FormBuilder, private store: Store, private pageGuard: PageGuard) {}

  ngOnInit(): void {
    this.store
      .select(selectCustomer)
      .pipe(take(1))
      .subscribe((storedCustomer: Customer) => {
        this.customer = storedCustomer;
      });

    this.initializeForm(this.customer);
    this.bankDetailsForm.emit(this.bankDetailsFormGroup);
  }

  formatInputIban(event: any): void {
    const value = event.target.value.toUpperCase();
    const countryCode = value.slice(0, 2); // Extract the first 2 letters

    if (['DE', 'LU', 'AT'].includes(countryCode)) {
      const formattedValue = value
        .replace(/\s+/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim();
      event.target.value = formattedValue;
    } else {
      event.target.value = value;
    }
  }

  revertIbanFormat(): void {
    const ibanControl = this.bankDetailsFormGroup.get('iban');
    if (ibanControl) {
      const value = ibanControl.value?.toUpperCase();
      const countryCode = value?.slice(0, 2); // Extract the first 2 letters

      if (['DE', 'LU', 'AT'].includes(countryCode)) {
        // Add spaces every 4 characters
        const formattedValue = value
          .replace(/\s+/g, '') // Remove existing spaces
          .replace(/(.{4})/g, '$1 ') // Add spaces every 4 characters
          .trim(); // Remove trailing spaces
        ibanControl.setValue(formattedValue, { emitEvent: false }); // Update without triggering events
      }
    }
  }

  private initializeForm(storedCustomer: Customer): void {
    this.bankDetailsFormGroup = this.fb.group({
      cardHolder: [storedCustomer?.cardHolder, [Validators.required]],
      iban: [storedCustomer?.iban, [Validators.required, this.ibanValidator()]],
      bic: [storedCustomer?.bic],
      creditInstitution: [storedCustomer?.creditInstitution, [Validators.required]]
    });
  }

  private ibanValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.replace(/\s+/g, ''); // Remove spaces before validation
      if (!value) {
        return { required: true };
      }

      // General IBAN regex pattern for length and format validation
      const ibanRegex = /^[A-Z]{2}[0-9A-Z]{1,30}$/;

      // Check general format first
      if (!ibanRegex.test(value)) {
        return { ibanInvalid: true };
      }

      // Specific checks for Germany, Luxembourg, and Austria
      if (value.startsWith('DE') && value.length !== 22) {
        return { ibanInvalid: true, reason: 'Ungültige IBAN: In Deutschland muss eine IBAN 22 Zeichen beinhalten.' };
      }
      if (value.startsWith('LU') && value.length !== 20) {
        return { ibanInvalid: true, reason: 'Ungültige IBAN: In Luxemburg muss eine IBAN 20 Zeichen beinhalten.' };
      }
      if (value.startsWith('AT') && value.length !== 20) {
        return { ibanInvalid: true, reason: 'Ungültige IBAN: In Österreich muss eine IBAN 20 Zeichen beinhalten.' };
      }

      return null; // Valid IBAN
    };
  }

  getErrorMessage(field: string): string {
    const control = this.bankDetailsFormGroup.get(field);

    if (control?.hasError('required')) {
      if (field === 'cardHolder') return 'Bitte geben Sie den Kontoinhaber von Vor- und Nachname ein.';
      if (field === 'iban') return 'Bitte geben Sie eine gültige IBAN an.';
      if (field === 'creditInstitution') return 'Bitte geben Sie den Namen Ihres Kreditinstituts an.';
    }

    if (control?.hasError('ibanInvalid') && field === 'iban') {
      const reason = control.getError('reason');
      return reason ? `${reason}` : 'Ungültiges IBAN-Format. Eine IBAN muss mit einem Länderkürzel beginnen.';
    }

    return '';
  }

  onHandleClick() {
    // Remove spaces from IBAN before submission
    const ibanControl = this.bankDetailsFormGroup.get('iban');
    if (ibanControl) {
      const ibanValue = ibanControl.value?.replace(/\s+/g, ''); // Remove all spaces
      ibanControl.setValue(ibanValue, { emitEvent: false }); // Update the value without emitting events
    }

    if (this.bankDetailsFormGroup.invalid) {
      this.bankDetailsFormGroup.markAllAsTouched();
    }

    this.onSubmit.emit();
  }
  onClickSupport() {
    this.pageGuard.setNavigatedFromValidUrl(true);
    this.router.navigate(['/umstellung/contact']);
  }
}
