import { Component, Input } from '@angular/core';
import * as faqsData from '../../../../../shared-assets/data/shared/faqs.json';
import { ContainerComponent } from '@oneqrew-partnertool/container';
import { SharedModule } from '@oneqrew-partnertool/utils';
import { AccordionComponent } from '@oneqrew-partnertool/accordion';

@Component({
  selector: 'lib-faqs',
  standalone: true,
  imports: [SharedModule, ContainerComponent, AccordionComponent], 
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent  {
  class = 'faqs';
  faqsData = faqsData

  changeClass(newClass: string): void {
    this.class = newClass;
  }

  @Input() isPaddingY = false;  

  get spacer(): string {
    return this.isPaddingY ? 'y-padding' : 'no-y-padding';
  }
}
