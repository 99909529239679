import { Component, Input } from '@angular/core';
import { SharedModule } from '@oneqrew-partnertool/utils';

@Component({
  selector: 'lib-tag',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss'
})
export class TagComponent {
  @Input() title = '';
  @Input() size = 'md';
  @Input() theme = 'blue';

  get dynamicClasses(): string {
    const baseClass = 'tag';
    const colorClass = this.theme ? `${baseClass}-${this.theme}` : '';
    const sizeClass = this.size ? `${baseClass}-${this.size}` : '';

    return `${baseClass} ${colorClass} ${sizeClass} ${this.size == 's' && 'small-text'}`.trim();
  }
}
