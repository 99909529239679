import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-list-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './list-item.component.html',
  styleUrl: './list-item.component.scss'
})
export class ListItemComponent {
  class = 'list-item';
  @Input() button = false;
  @Input() icon = '';
  @Input() text = '';
  @Input() disabled = false;
  @Output() action = new EventEmitter<void>();

  get theIcon(): string {
    switch (this.icon) {
      case 'plus':
        return 'plus-icon';
      case 'orange-check':
        return 'checkbox-blue-icon';
      default:
        return 'checkbox-icon';
    }
  }

  handleClick(): void {
    if (this.button) {
      this.action.emit();
    }
  }
}
