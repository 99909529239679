/**
 * BlueSolution Subscription Management API
 * Last changed: 2024-11-18T10:02:49+00:00
 *
 * OpenAPI spec version: 1.0.9
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ModelError { 
    timestamp?: Date;
    path?: string;
    status?: number;
    error?: string;
    message?: string;
}