import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { Plan } from '@oneqrew-partnertool/models';
import { selectFetchedPlans } from '@oneqrew-partnertool/core';
@Injectable({
  providedIn: 'root'
})
export class PlanCalculatorService {
  private cachedDeduction = 0;
  private extraModules: any[] = [];

  constructor(private store: Store) {
    this.initializeDeduction();
  }

  getRebate(plan: Plan): number {
    const rebatePercent = this.calcDiscount(plan) || 0.3;
    return this.getSubtotal(plan) * rebatePercent;
  }

  calcDiscount(plan: Plan): number {
    return plan.discount / 100;
  }

  getTotal(plan: Plan): number {
    return (
      this.getSubtotal(plan) +
      this.getCloudVersionCost(plan.cloudVersion) -
      this.getRebate(plan) +
      this.getTotalDeviceCost(plan.devicesCount)
    );
  }

  getTotalWithoutCloudVersion(plan: Plan): number {
    return this.getSubtotal(plan) - this.getRebate(plan);
  }

  getSubtotalWithAddedDevices(plan: Plan): number {
    return this.getSubtotal(plan);
  }

  getSubtotal(plan: Plan): number {
    const T = plan.price; // Treaty base price
    const M = this.getAdditionalModulesPrice(plan); // Sum of other additional modules
    const N = plan.workStationCount; // Number of workspace users

    // Full formula calculation
    let total: number;
    if (N === 0) {
      // If there are no workstations, use a simplified formula
      total = T + M + this.getTotalWorkStationCostWithModule(plan) + this.getClientsCost(plan.clientsCount);
    } else {
      // If there are workstations, apply the full formula
      total = this.getTotalWorkStationCostWithModule(plan) + this.getClientsCost(plan.clientsCount) + T + M;
    }

    return total;
  }

  getPlanItemstotal(plan: Plan): number {
    return plan.price + this.getTotalDeviceCost(plan.devicesCount) + this.getTotalWorkStationCost(plan.planType, plan.workStationCount);
  }

  getTotalDeviceCost(deviceCount: number) {
    const devicePrice = 4.9;
    return deviceCount * devicePrice;
  }

  getCloudVersionCost(cloudVersion: boolean) {
    return cloudVersion ? 20 : 0;
  }

  getTotalWorkStationCostWithModule(plan: Plan): number {
    return this.getTotalWorkStationCostDisplay(plan) * plan.workStationCount;
  }

  getTotalWorkStationCostDisplay(plan: Plan): number {
    return (plan.price + this.getClientsCost(plan.clientsCount) + this.getAdditionalModulesPrice(plan)) / 2;
  }

  getClientsCost(clientsCount: number) {
    const clientPrice = 20;
    return clientsCount * clientPrice;
  }

  // getTotalWorkStationCost(planType: string, workstation: number) {
  //   const workstationPrice = planType === 'smarthandwerk pro L-Paket' ? 64.5 : 39.5;
  //   return workstation * workstationPrice;
  // }

  getTotalWorkStationCost(planType: string, workstation: number): number {
    let workstationPrice = 0;

    this.store
      .select(selectFetchedPlans)
      .pipe(take(1))
      .subscribe((fetchedPlans) => {
        const matchedPlan = fetchedPlans.find((plan) => plan.planType === planType);
        if (matchedPlan) {
          workstationPrice = matchedPlan.price / 2 || 0;
        } else {
          console.warn(`Plan not found for type: ${planType}`);
        }
      });

    return workstation * workstationPrice;
  }

  private initializeDeduction(): void {
    this.store
      .select(selectFetchedPlans)
      .pipe(take(1))
      .subscribe((fetchedPlans) => {
        if (!fetchedPlans || fetchedPlans.length === 0) {
          console.warn('Fetched Plans are empty. Ensure the store is updated before this component initializes.');
        } else {
          const selectedPlan = fetchedPlans[1];
          if (selectedPlan && selectedPlan.additionalModules) {
            this.extraModules = selectedPlan.additionalModules;
            this.cachedDeduction = this.extraModules.reduce((total, module) => total + (module.monthlyPrice || 0), 0);
          } else {
            console.warn('No additionalModules found in the selected plan.');
          }
        }
      });
  }

  getAdditionalModulesPrice(plan: Plan): number {
    let additionalModulesCost = (plan.additionalModules ?? []).reduce((total, module) => total + (module.price || 0), 0);

    if (plan.planType === 'smarthandwerk pro L-Paket') {
      const deductModulePrice = this.cachedDeduction;
      additionalModulesCost -= deductModulePrice;
    }

    // Ensure total cost doesn't go below 0
    return Math.max(additionalModulesCost, 0);
  }

  getModuleCalculatedPrice(modulePrice: number, moduleName: string, workstationCount: number, planType: string) {
    const isExtraModule = this.extraModules.some((module) => module.name === moduleName);

    // Saving logic incase to be reverted
    // if (planType === 'smarthandwerk pro L-Paket' && isExtraModule) {
    //   // plan L
    //   if (workstationCount !== 0) {
    //     price += workstationCount * (modulePrice / 2) + modulePrice - modulePrice;
    //   } else {
    //     price = 0;
    //   }
    // } else {
    //   // Plan M
    //   if (workstationCount !== 0) {
    //     price += workstationCount * (modulePrice / 2) + modulePrice;
    //   } else {
    //     price = modulePrice;
    //   }
    // }

    if (planType === 'smarthandwerk pro L-Paket' && isExtraModule) {
      modulePrice = 0;
    }

    return modulePrice;
  }

  getDiscountedPlanPrice(plan: Plan): number {
    let discount = this.getPlanPrice(plan) * this.calcDiscount(plan) || 0.3;
    return this.getPlanPrice(plan) - discount;
  }

  getPlanPrice(plan: Plan): number {
    return plan.price;
  }
}
