import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectCustomer, setCustomer } from '@oneqrew-partnertool/core';
import { FaqsComponent } from '@oneqrew-partnertool/faqs';
import { Customer } from '@oneqrew-partnertool/models';
import { OrderContactComponent } from '@oneqrew-partnertool/order-contact';
import { OrderDetailsComponent } from '@oneqrew-partnertool/order-details';
import { OrderOverviewComponent } from '@oneqrew-partnertool/order-overview';
import { OrderSummaryComponent } from '@oneqrew-partnertool/order-summary';
import { PageGuard, RouteParam, SharedModule } from '@oneqrew-partnertool/utils';

@Component({
  selector: 'app-order-page',
  standalone: true,
  imports: [OrderOverviewComponent, FaqsComponent, OrderContactComponent, OrderDetailsComponent, OrderSummaryComponent, SharedModule],
  templateUrl: './order-page.component.html'
})
export class OrderPageComponent implements OnInit {
  pageTitle: string = 'Umstellung shp - Kunden- und Bestellinformationen';
  orderForm!: FormGroup;
  userID: string = '';
  customer!: Customer;

  constructor(
    private titleService: Title,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private pageGuard: PageGuard
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.pageTitle);
    this.orderForm = this.fb.group({});
    this.userID = RouteParam.getParamAsString(this.route, 'id');
    this.store.select(selectCustomer).subscribe((customer) => {
      this.customer = customer;
    });

    if (!this.customer || Object.keys(this.customer).length === 0 || !this.customer.customerCode) {
      this.router.navigate(['/']);
    }
  }

  onFormSubmit() {
    if (this.orderForm.valid) {
      const updatedCustomer = {
        ...this.customer,
        firstName: this.orderForm.value.firstName,
        lastName: this.orderForm.value.lastName,
        email: this.orderForm.value.email,
        confirmEmail: this.orderForm.value.confirmEmail,
        company: this.orderForm.value.company,
        street: this.orderForm.value.street,
        addressTwo: this.orderForm.value.addressTwo,
        postCode: this.orderForm.value.postCode,
        city: this.orderForm.value.city,
        salutation: this.orderForm.value.salutation,
        salutationOpener: this.orderForm.value.salutationOpener
      };

      this.pageGuard.setNavigatedFromValidUrl(true);
      this.store.dispatch(setCustomer(updatedCustomer));
      this.router.navigate(['/umstellung/checkout/', this.userID]);
    } else {
      this.orderForm.markAllAsTouched();
      console.error('Form is invalid');
    }
  }

  formInitialized(form: FormGroup) {
    for (const proprty in form.controls) {
      this.orderForm.setControl(proprty, form.controls[proprty]);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    $event.returnValue = 'Beim erneuten Laden der Seite gehen die ausgewählten Module und der Plan verloren.';
  }
}
