/**
 * BlueSolution Subscription Management API
 * Last changed: 2024-11-18T10:02:49+00:00
 *
 * OpenAPI spec version: 1.0.9
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomerContactDetails } from './customerContactDetails';
import { CustomerSalesPartner } from './customerSalesPartner';
import { CustomerSepa } from './customerSepa';

/**
 * A customer is buying BlueSolution or OneQrew Licenses. A partner manages multiple customers. A customer is a profitcenter and can have multiple users assigned.
 */
export interface CustomerCustomer { 
    knr: string;
    legacyProductName?: string;
    hasAcceptedAgb?: boolean;
    id?: number;
    contactDetails?: CustomerContactDetails;
    salesPartner?: Array<CustomerSalesPartner>;
    payment?: CustomerSepa;
}