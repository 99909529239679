import { Component, Input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContainerComponent } from '@oneqrew-partnertool/container';

@Component({
  selector: 'lib-order-overview',
  standalone: true,
  imports: [CommonModule, ContainerComponent],
  templateUrl: './order-overview.component.html',
  styleUrl: './order-overview.component.scss'
})
export class OrderOverviewComponent {
  class = 'order-overview';
  @Input() heading!: TemplateRef<any>;
  @Input() leftContent!: TemplateRef<any>;
  @Input() rightContent!: TemplateRef<any>;
}
