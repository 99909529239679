import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ContainerComponent } from '@oneqrew-partnertool/container';
import { SharedModule } from '@oneqrew-partnertool/utils';
import { selectPlan } from '@oneqrew-partnertool/core';
import { Plan } from '@oneqrew-partnertool/models';
import * as textData from '../../../../../shared-assets/data/pages/front-page.json';
@Component({
  selector: 'lib-promotion-banner',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './promotion-banner.component.html',
  styleUrl: './promotion-banner.component.scss'
})
export class PromotionBannerComponent implements OnInit {
  plan$!: Observable<Plan>;
  class = 'promotion-banner';
  textData = textData;
  selectedPlan = 'selectedPlan';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.plan$ = this.store.select(selectPlan);

    // Update selectedPlan when plan$ emits a value
    this.plan$.subscribe((plan: Plan) => {
      this.selectedPlan = plan.planType; // Assuming 'planType' holds the plan name
    });
  }

  get updatedDescription(): string {
    return this.textData.promotionBanner.description.replace('selectedPlan', this.selectedPlan);
  }
}
