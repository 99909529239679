<form [formGroup]="contactForm" autocomplete="on">
  <div [ngClass]="class">
    <div [ngClass]="class + '__header text-primary-color'">
      <h4>Kontakdaten</h4>
      <ng-content *ngIf="tag">
        <lib-tag title="Übernommen aus vorheriger Eingabe" [size]="tagSize"></lib-tag>
      </ng-content>
    </div>

    <div class="grid">
      <!-- Salutation -->
      <div class="col-4">
        <div [ngClass]="class + '__field'">
          <label class="required" for="salutation">Anrede</label>
          <p-dropdown [options]="salutationSelect" formControlName="salutation" placeholder="Anrede"></p-dropdown>
          <span class="small-text text-highlight" *ngIf="contactForm.get('salutation')?.invalid && contactForm.get('salutation')?.touched">
            {{ getErrorMessage('salutation', 'Anrede') }}
          </span>
        </div>
      </div>

      <!-- Salutation Opener 
      <div class="col-9">
        <div [ngClass]="class + '__field'">
          <label for="salutationOpener">Anredeöffner</label>
          <input id="salutationOpener" pInputText formControlName="salutationOpener" />
          <span
            class="small-text text-highlight"
            *ngIf="contactForm.get('salutationOpener')?.invalid && contactForm.get('salutationOpener')?.touched"
          >
            {{ getErrorMessage('salutationOpener', 'Anredeöffner') }}
          </span>
        </div>
      </div>-->
      <input id="salutationOpener" pInputText formControlName="salutationOpener" hidden />

      <!-- First Name -->
      <div class="col-8">
        <div [ngClass]="class + '__field'">
          <label for="firstName" class="required">Vorname</label>
          <input id="firstName" pInputText formControlName="firstName" />
          <span class="small-text text-highlight" *ngIf="contactForm.get('firstName')?.invalid && contactForm.get('firstName')?.touched">
            {{ getErrorMessage('firstName', 'Vorname') }}
          </span>
        </div>
      </div>

      <!-- Last Name -->
      <div class="col-12">
        <div [ngClass]="class + '__field'">
          <label for="lastName" class="required">Name</label>
          <input id="lastName" pInputText formControlName="lastName" />
          <span class="small-text text-highlight" *ngIf="contactForm.get('lastName')?.invalid && contactForm.get('lastName')?.touched">
            {{ getErrorMessage('lastName', 'Name') }}
          </span>
        </div>
      </div>

      <!-- Email -->
      <div class="col-12">
        <div [ngClass]="class + '__field'">
          <label for="email" class="required">E-Mail-Adresse</label>
          <input id="email" type="email" pInputText formControlName="email" />
          <span class="small-text text-highlight" *ngIf="contactForm.get('email')?.invalid && contactForm.get('email')?.touched">
            {{ getErrorMessage('email', 'E-Mail-Adresse') }}
          </span>
        </div>
      </div>

      <!-- Confirm Email -->
      <div class="col-12">
        <div [ngClass]="class + '__field'">
          <label for="confirmEmail" class="required">E-Mail-Adresse wiederholen</label>
          <input id="confirmEmail" type="email" pInputText formControlName="confirmEmail" />
          <span
            class="small-text text-highlight"
            *ngIf="contactForm.get('confirmEmail')?.invalid && contactForm.get('confirmEmail')?.touched"
          >
            {{ getErrorMessage('confirmEmail', 'E-Mail-Adresse wiederholen') }}
          </span>
        </div>
      </div>

      <!-- Company -->
      <div class="col-12">
        <div [ngClass]="class + '__field'">
          <label for="company">Firma</label>
          <input id="company" pInputText formControlName="company" />
          <span class="small-text text-highlight" *ngIf="contactForm.get('company')?.invalid && contactForm.get('company')?.touched">
            {{ getErrorMessage('company', 'Firma') }}
          </span>
        </div>
      </div>

      <!-- Street -->
      <div class="col-12">
        <div [ngClass]="class + '__field'">
          <label for="streetAddress" class="required">Straße und Hausnr.</label>
          <input id="streetAddress" pInputText formControlName="street" />
          <span class="small-text text-highlight" *ngIf="contactForm.get('street')?.invalid && contactForm.get('street')?.touched">
            {{ getErrorMessage('street', 'Straße') }}
          </span>
        </div>
      </div>

      <!-- Address Line 2 -->
      <div class="col-12">
        <div [ngClass]="class + '__field'">
          <label for="addressTwo">Adresszeile 2</label>
          <input id="addressTwo" pInputText formControlName="addressTwo" />
          <span class="small-text text-highlight" *ngIf="contactForm.get('addressTwo')?.invalid && contactForm.get('addressTwo')?.touched">
            {{ getErrorMessage('addressTwo', 'Adresszeile 2') }}
          </span>
        </div>
      </div>

      <!-- Postcode -->
      <div class="col-12">
        <div [ngClass]="class + '__field'">
          <label for="postCode" class="required">Postleitzahl</label>
          <input id="postCode" pInputText formControlName="postCode" />
          <span class="small-text text-highlight" *ngIf="contactForm.get('postCode')?.invalid && contactForm.get('postCode')?.touched">
            {{ getErrorMessage('postCode', 'Postleitzahl') }}
          </span>
        </div>
      </div>

      <!-- City -->
      <div class="col-12">
        <div [ngClass]="class + '__field'">
          <label for="city" class="required">Stadt</label>
          <input id="city" pInputText formControlName="city" />
          <span class="small-text text-highlight" *ngIf="contactForm.get('city')?.invalid && contactForm.get('city')?.touched">
            {{ getErrorMessage('city', 'Stadt') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</form>

<form autocomplete="on" style="visibility: hidden; height: 0; overflow: hidden"></form>
