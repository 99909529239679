import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ContainerComponent } from '@oneqrew-partnertool/container';
import { SharedModule, DateFormatterService } from '@oneqrew-partnertool/utils';

@Component({
  selector: 'lib-success-message',
  standalone: true,
  imports: [ContainerComponent, SharedModule],
  templateUrl: './success-message.component.html',
  styleUrls: [
    './success-message.component.scss',
    './success-message-circle-progress.component.scss',
    './success-message-text.component.scss'
  ]
})
export class SuccessMessageComponent implements OnInit {
  class = 'success';
  dateSent!: string;
  isNewPlanPage = false;

  constructor(private dateFormatterService: DateFormatterService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe((data: { [key: string]: any }) => {
      this.isNewPlanPage = data['isNewPlanPage'];
    })
    this.dateSent = this.dateFormatterService.formatDate(new Date());
  }
}
