<header class="{{ class }}">
  <div class="{{ class + '__container' }}">
    <lib-container>
      <button type="button" (click)="handleClick()" class="{{ class + '__link' }}">
        <img
          [src]="(isSubPage ? 'assets/img/icons/arrow-left-icon' : 'assets/img/site-logo') + '.svg'"
          [alt]="isSubPage ? 'Back Button' : 'Site Logo'"
          [width]="isSubPage ? '24' : '409.41'"
          [height]="isSubPage ? '24' : '56'"
          class="{{ class }}__logo"
        />

        <span class="text-primary-color" *ngIf="isSubPage">Zurück</span>
      </button>
    </lib-container>
  </div>
</header>
