<form [formGroup]="contactForm" autocomplete="on">
  <div [ngClass]="class">
    <div [ngClass]="class + '__header text-primary-color'">
      <h4>Kundeninformationen</h4>
    </div>

    <div class="grid">
      <!-- Customer Number -->
      <div class="col-12">
        <div [ngClass]="class + '__field'">
          <label for="bsNumber">Kundennummer (falls vorhanden)</label>
          <input id="bsNumber" pInputText formControlName="bsNumber" />
          <span class="small-text text-highlight" *ngIf="contactForm.get('bsNumber')?.invalid && contactForm.get('bsNumber')?.touched">
            {{ getErrorMessage('bsNumber', 'bsNumber') }}
          </span>
        </div>
      </div>

      <!-- salesPartner -->
      <div class="col-12">
        <div [ngClass]="class + '__field'">
          <label for="salesPartner">Fachhandelspartner</label>
          <p-dropdown [options]="salesPartnerSelect" [(ngModel)]="selectedSalesPartner" formControlName="salesPartner" [filter]="true" filterBy="label" [showClear]="true"></p-dropdown>
          <span class="small-text text-highlight" *ngIf="contactForm.get('salesPartner')?.invalid && contactForm.get('salesPartner')?.touched">
            {{ getErrorMessage('salesPartner', 'Fachhandelspartner') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</form>

<form autocomplete="on" style="visibility: hidden; height: 0; overflow: hidden"></form>
