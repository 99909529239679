<div [ngClass]="class" *ngIf="customer$ | async as customer">
  <div class="flex justify-content-center">
    <div [ngClass]="class + '__company'">
      <lib-tag [theme]="'highlight'" [title]="'Aktueller Vertrag'"></lib-tag>
    </div>
  </div>
  <div class="{{ class + '__header flex justify-content-between pt-2' }}">
    <div>
      <h4 class="mb-2">{{ subscriptionData.subscriptionOverview.product }}</h4>
    </div>
  </div>

  <p class="white-space-nowrap">
    Erfahren Sie mehr unter
    <a href="https://smarthandwerk.de/pro/" class="text-underlined" target="_blank">https://smarthandwerk.de/pro/</a>
  </p>
  <p *ngIf="customer.contractRenewal">
    Vertragserneuerung am {{ customer.contractRenewal | date:'dd.MM.yyyy' }}
  </p>

  <img [ngClass]="class + '__arrow'" src="assets/img/subscription-arrow.svg" alt="Subscription Arrow" width="162"
    height="52" />

  <div class="mt-3">
    <p *ngIf="customer?.functionModules?.length" class="mb-2 text-bold">
      {{ subscriptionData.subscriptionOverview['features'].header || 'Funktionen' }}
    </p>
    <ul [ngClass]="class + '__function-list'">
      <li *ngFor="let item of customer?.functionModules">
        <i class="pi pi-check"></i>
        {{ item.amount + ' ' + item.name | moduleFormat }}
      </li>
    </ul>
    <p *ngIf="customer?.modules?.length" class="mb-2 text-bold">{{
      subscriptionData.subscriptionOverview['module'].header }}</p>
    <ul [ngClass]="class + '__module-list'">
      <li *ngFor="let item of customer?.modules">
        <span [ngClass]="class + '__module-list__module-name'">
          <i class="pi pi-plus"></i>
          {{ item.name }}
        </span>
      </li>
    </ul>
  </div>
  <div [ngClass]="class + '__hint'">
    <p class="flex mb-2"><img class="my-auto mr-1" src="assets/img/icons/warning.png" alt="Warning">
      <span class="font-bold text-dark-grey-2">{{ subscriptionData.subscriptionOverview['hint'].warning }}
      </span>
    </p>
    <p class="text-dark-grey-2">{{ subscriptionData.subscriptionOverview['hint'].text }}</p>
  </div>
</div>