import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BASE_PATH, CustomerService, OneqrewService } from '@oneqrew-partnertool/api';
import { BaseComponent } from '@oneqrew-partnertool/base';
import { environment, getAppConfigProvider } from '@oneqrew-partnertool/config';
import { customerReducer, planReducer, salesPartnersReducer, subscriptionPlansReducer } from '@oneqrew-partnertool/core';
import { SharedModule } from '@oneqrew-partnertool/utils';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AppComponent } from '../app/app.component';
import { appRoutes } from './app.routes';

export function sessionStorageSyncReducer(reducer: any): any {
  return localStorageSync({
    keys: ['customer', 'plans'],
    rehydrate: true,
    storage: sessionStorage
  })(reducer);
}
const metaReducers: MetaReducer<any>[] = [sessionStorageSyncReducer];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      scrollPositionRestoration: 'top'
    }),
    // Configure StoreModule with metaReducers
    StoreModule.forRoot({}, { metaReducers }),
    StoreModule.forFeature('customer', customerReducer),
    StoreModule.forFeature('plans', planReducer),
    StoreModule.forFeature('salesPartners', salesPartnersReducer),
    StoreModule.forFeature('subscriptionPlans', subscriptionPlansReducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      trace: true
    }),
    SharedModule,
    BaseComponent
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    getAppConfigProvider(environment),
    {
      provide: BASE_PATH,
      useValue: environment.landingPage.apiURL
    },
    CustomerService,
    OneqrewService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
