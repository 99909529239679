<div class="{{ class }}" *ngIf="customer$ | async as customer">
  <div class="{{ class + '__header' }}">
    <p class="h4 text-white">{{ contactData['ctaTitle'] }}</p>
    <ng-container *ngIf="customer?.salesPartner?.contactDetails?.phoneNr || customer?.salesPartner?.contactDetails?.mobilePhoneNr">
      <lib-tag
        [title]="
          'Tel: ' +
          (customer?.salesPartner?.contactDetails?.phoneNr?.toString() ||
            customer?.salesPartner?.contactDetails?.mobilePhoneNr?.toString() ||
            contactData['ctaTag'])
        "
        [theme]="'white'"
        [size]="'s'"
      ></lib-tag>
    </ng-container>
  </div>
  <p class="text-white mb-3">{{ contactData['hint'] }}</p>
  <a (click)="pageGuard.setNavigatedFromValidUrl(true)" [routerLink]="['/umstellung/contact', userID]" [ngClass]="class + '__link'">
    <span class="{{ class + '__link-title text-center' }}">
      {{ customer?.salesPartner?.contactDetails?.companyName + ' kontaktieren' }}
    </span>
  </a>
</div>
