<section class="{{ class }}" *ngIf="plan$ | async as plan">
  <lib-container>
    <div class="{{ class + '__header mb-3 pb-2' }}">
      <p class="h4">Ihre neuen Vertragsdaten</p>

      <p><strong>Kosten</strong></p>
    </div>

    <div class="{{ class + '__new-plan' }}">
      <div class="{{ class + '__plan-details pb-5 mb-5' }}">
        <div class="{{ class + '__plan column' }}">
          <div class="{{ class + '__tag' }}">
            <lib-tag title="Neuer Plan" theme="blue" size="s"></lib-tag>
          </div>

          <p class="h4">{{ plan.planType }}</p>
        </div>

        <div class="{{ class + '__functions column' }}">
          <div class="{{ class + '__item' }}">
            <lib-list-item icon="check" [text]="plan.modules[0].name"></lib-list-item>
          </div>
          <div class="{{ class + '__item' }}">
            <lib-list-item icon="check" [text]="'Grundmodul ' + (plan.planType | basicModuleText)"></lib-list-item>
          </div>
          <div class="{{ class + '__item' }}" *ngIf="plan.cloudVersion">
            <lib-list-item icon="check" text="Cloud Version"></lib-list-item>
          </div>
          <div class="{{ class + '__item' }}" *ngIf="plan.devicesCount !== 0">
            <lib-list-item icon="check" [text]="plan.devicesCount + (plan.devicesCount | pluralize : 'device')"></lib-list-item>
          </div>
          <div class="{{ class + '__item' }}" *ngIf="plan.clientsCount !== 0">
            <lib-list-item icon="check" [text]="plan.clientsCount + (plan.clientsCount | pluralize : 'client')"></lib-list-item>
          </div>
          <div class="{{ class + '__item' }}" *ngIf="plan.workStationCount !== 0">
            <lib-list-item
              icon="check"
              [text]="plan.workStationCount + (plan.workStationCount | pluralize : 'workStation')"
            ></lib-list-item>
          </div>
          <div class="{{ class + '__item' }}" *ngFor="let item of plan.modules; let isFirst = first">
            <lib-list-item *ngIf="!isFirst" [text]="item.name" icon="orange-check"></lib-list-item>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="plan?.additionalModules?.length ?? 0 > 0" class="{{ class + '__module pb-5' }}">
      <div class="{{ class + '__module-header' }}">
        <div class="mb-3">
          <lib-tag title="Modul" theme="blue" size="s"></lib-tag>
        </div>
      </div>

      <div *ngFor="let item of plan?.additionalModules ?? []">
        <p class="h4 mb-2">{{ item.name }}</p>
      </div>
    </div>

    <div class="{{ class + '__total mt-5' }}">
      <p class="h4">Gesamtkosten</p>

      <p class="text-primary-color text-bold">
        {{ planCalculatorService.getTotal(plan) | euroCurrency | perMonth }}
      </p>
    </div>

    <p class="text-primary-color mt-6 text-center">
      Wunschtermin Vertragsumstellung:
      <span class="text-priamry-color text-bold">{{ plan.startDate | customDateFormat }}</span>
    </p>
  </lib-container>
</section>
