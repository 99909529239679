export class ContactDetails {
  id!: number;
  phoneNr?: string | null;
  email?: string | null;
  postalCode?: string | null;
  street?: string | null;
  city?: string | null;
  mobilePhoneNr?: string | null;
  companyName?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  addressExtra?: string | null;
  salutationOpener?: string | null;
  salutation?: string | null;
  country?: string | null;
  companyCeo?: string | null;
  bsNumber?: string | null;
}

export class SalesPartnerPayload {
  id?: number;
  name?: string;
  knr?: string;
  companyLogoImg?: string | null;
  signatureImg?: string | null;
  websiteUrl?: string | null;
  faxNr?: string | null;
  ustIdNr?: string | null;
  taxNr?: string | null;
  shareHolder?: string | null;
  commercialRegister?: string | null;
  registerCourt?: string | null;
  contactDetails?: ContactDetails;
}

export class CustomerPayload {
  id!: number;
  knr?: string;
  legacyProductName?: string;
  hasAcceptedAgb?: boolean;
  hasAcceptedPrivacyAgreement?: boolean;
  contactDetails?: ContactDetails;
  salesPartner?: SalesPartnerPayload;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payment?: any;
}

export class ProductPackage {
  name!: string;
  monthlyPrice!: number;
  type!: string;
}

export class ExtraModule {
  id!: number;
  name!: string;
  monthlyPrice!: number;
  isExtra!: boolean;
}

export class OneqrewSubscription {
  amountDevices!: number;
  amountWorkspaces!: number;
  orderedAt!: string;
  endOfContract!: string;
  startOfContract!: string;
  isCloudVersion!: boolean;
  amountClients!: number;
  monthlyCostSum!: number;
  productPackage!: ProductPackage;
  extraModules?: ExtraModule[];
}

export class Sepa {
  id?: number;
  iban!: string;
  bic!: string;
  creditInstitution!: string;
  accountOwner!: string;
}

export class UpdateSubscription {
  id!: number;
  contactdetails?: ContactDetails;
  customer?: CustomerPayload;
  oneqrewsubscription?: OneqrewSubscription;
  sepa?: Sepa;
}
