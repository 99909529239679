import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe that formats a given date to the format 'dd.mm.yy'.
 */
@Pipe({
  name: 'customDateFormat'
})
export class CustomDateFormatPipe implements PipeTransform {
  /**
   * Transforms a date value into the format 'dd.mm.yy'.
   *
   * @param value - The date value to format. Can be a Date object, string, or number (timestamp).
   * @returns A string representing the formatted date in 'dd.mm.yy' format. Returns an empty string if the input value is invalid.
   */
  transform(value: Date | string | number): string {
    // Return an empty string if the input value is not provided
    if (!value) {
      return '';
    }

    // Convert the input value to a Date object
    const date = new Date(value);

    // Extract the day of the month and ensure it has two digits
    const day = ('0' + date.getDate()).slice(-2);

    // Extract the month (0-based, so add 1) and ensure it has two digits
    const month = ('0' + (date.getMonth() + 1)).slice(-2);

    // Extract the last two digits of the year
    const year = date.getFullYear().toString().slice(-2);

    // Return the formatted date in 'dd.mm.yy' format
    return `${day}.${month}.${year}`;
  }
}
