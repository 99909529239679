import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'euroCurrency'
})
export class EuroCurrencyPipe implements PipeTransform {
  transform(value: number): string {
    // Format the number using German locale
    const formattedValue = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);

    // Return the formatted value with the Euro symbol on the right
    return `${formattedValue}€`;
  }
}
