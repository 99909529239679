<section class="{{ class }}">
  <lib-container>
    <div class="{{ class + '__content' }}">
      <div class="{{ class + '__left' }}">
        <lib-promotion-banner></lib-promotion-banner>
        <lib-subscription-overview [customer]="(customer$ | async)!"></lib-subscription-overview>
        <lib-contact-cta [customer]="(customer$ | async)!"></lib-contact-cta>
      </div>

      <div class="{{ class + '__right' }}">
        <ng-container *ngIf="isInitialized; else loading">
          <lib-plan-selector
            [company]="planUpgradeData.planUpgrade['header'].company"
            [tag]="planUpgradeData.planUpgrade['header'].tag"
            [plan]="(plan$ | async)!"
            [disclaimer]="
              planUpgradeData.planUpgrade['disclaimer']
            "
            [isSubscriptionPlans]="false"
            [customer]="(customer$ | async)!"
          ></lib-plan-selector>
        </ng-container>
      </div>
    </div>

    <ng-template #loading>
      <h4>
        Bitte warten...
        <i class="pi pi-spin pi-spinner"></i>
      </h4>
    </ng-template>
  </lib-container>
</section>
