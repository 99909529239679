<div [ngClass]="class">
  <lib-container>
    <h3 class="text-center mb-3">
      {{ textData.subscriptionPlans.PlanSubscriptionTitle }}
    </h3>

    <p class="{{ class + '__subtitle text-center text-dark-grey' }}">
      {{ textData.subscriptionPlans.PlanSubscriptionSubTitle }}
    </p>
    <div class="grid">
      <div class="col-12 w-full xl:col-6 xl:w-6 flex-column" *ngFor="let plan of plans">
        <ng-container *ngIf="isNewCustomerPlan; else upgradePlan">
          <lib-new-plan-selector [activePlan]="(plan$ | async)!" [isSubscriptionPlans]="true" [plan]="plan"></lib-new-plan-selector>
        </ng-container>
        <ng-template #upgradePlan>
          <lib-plan-selector [activePlan]="(plan$ | async)!" [isSubscriptionPlans]="true" [plan]="plan"></lib-plan-selector>
        </ng-template>
      </div>
    </div>
  </lib-container>
</div>
