<section class="{{ class }}">
  <lib-container>
    <div class="text-center">
      <ng-container *ngIf="heading">
        <ng-container *ngTemplateOutlet="heading"></ng-container>
      </ng-container>
    </div>
    <div class="{{ class + '__content' }}">
      <div class="{{ class + '__left' }}">
        <ng-container>
          <ng-container *ngTemplateOutlet="leftContent"></ng-container>
        </ng-container>
      </div>
      <div class="{{ class + '__right' }}">
        <ng-container *ngIf="rightContent">
          <ng-container *ngTemplateOutlet="rightContent"></ng-container>
        </ng-container>
      </div>
    </div>
  </lib-container>
</section>
