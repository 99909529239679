<ng-container *ngIf="isNewPlanPage; else planUpgrade">
    <lib-new-plan></lib-new-plan>
    <lib-subscription-plans [isNewCustomerPlan]="isNewPlanPage"></lib-subscription-plans>
    <lib-module-addons></lib-module-addons>
</ng-container>
<ng-template #planUpgrade>
    <lib-plan-upgrade></lib-plan-upgrade>
    <lib-module-addons></lib-module-addons>
    <lib-subscription-plans></lib-subscription-plans>
    <lib-faqs [isPaddingY]="true"></lib-faqs>
</ng-template>



