import { AppConfig } from '../app.config';

export const environment: AppConfig = {
  landingPage: {
    clientId: 'landingpage',
    apiURL: 'https://app-api-bss-abo-d-euwe-09b677.azurewebsites.net',
    recaptcha: '6Ld5i1UqAAAAACmiltGTLoFCNJXkWXcV36bxzG1t',
    cookieYes: false
  }
};
