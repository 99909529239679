import { createReducer, on } from '@ngrx/store';
import { Customer } from '@oneqrew-partnertool/models';
import { setCustomer } from './customer.action';

export interface CustomerState {
  customer: Customer;
}

export const initialCustomerState: CustomerState = {
  customer: new Customer()
};

export const customerReducer = createReducer(
  initialCustomerState,

  on(setCustomer, (state, customer) => ({ ...state, customer }))
);
