<div [ngClass]="class">
  <div [ngClass]="class + '__header text-primary-color'">
    <h4>{{ orderDetailsData['header'] }}</h4>
  </div>
  <!-- <div [ngClass]="class + '__sepa-container'">
    <div class="{{ class + '__icon' }}">
      <img src="assets/img/icons/credit-card.svg" alt="Sepa Icon" width="24" height="24" />
      <span class="text-highlight">{{ orderDetailsData['sepa'] }}</span>
    </div>
  </div> -->
  <p class="text-dark-grey" [innerHTML]="orderDetailsData['paymentInfo']"></p>
</div>
