/**
 * BlueSolution Subscription Management API
 * Last changed: 2025-03-05T10:54:50+00:00
 *
 * OpenAPI spec version: 1.0.13
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BsslegacyLegacyModule } from './bsslegacyLegacyModule';
import { CustomerCustomer } from './customerCustomer';

export interface CustomerLegacySubscription { 
    /**
     * This can only be greater than one if module.canHaveMultiple == true
     */
    amount: number;
    contractEndsAt?: string;
    contractRenewal?: string;
    id?: number;
    legacyModule?: Array<BsslegacyLegacyModule>;
    customer?: Array<CustomerCustomer>;
}