import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Customer } from '@oneqrew-partnertool/models';
import { selectCustomer } from '@oneqrew-partnertool/core';

@Injectable({
  providedIn: 'root'
})
export class CustomerResolver implements Resolve<Customer | null> {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Customer | null> {
    return this.store.select(selectCustomer).pipe(
      filter((customer) => !!customer), // Ensure a valid Plan is emitted
      take(1) // Complete after one valid emission
    );
  }
}
