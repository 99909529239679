import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setPlan } from '@oneqrew-partnertool/core';
import { FaqsComponent } from '@oneqrew-partnertool/faqs';
import { Plan } from '@oneqrew-partnertool/models';
import { OrderSuccessComponent } from '@oneqrew-partnertool/order-success';
import { CanComponentDeactivate, PageGuard, RouteParam, handlePlanCheck } from '@oneqrew-partnertool/utils';

@Component({
  selector: 'app-success-page',
  standalone: true,
  imports: [OrderSuccessComponent, FaqsComponent],
  templateUrl: './success-page.component.html'
})
export class SuccessPageComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  pageTitle: string = 'Umstellung shp - Erfolgreich bestellt';
  userID = '';
  plan!: Plan;
  exitAlert = true;

  constructor(
    private titleService: Title,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private pageGuard: PageGuard
  ) {}

  ngOnInit(): void {
    this.userID = RouteParam.getParamAsString(this.route, 'id');
    this.titleService.setTitle(this.pageTitle);

    handlePlanCheck(
      this.store,
      () => {
        return;
      },
      () => {
        sessionStorage.clear();
        this.router.navigate(['/']);
      }
    );
  }

  ngOnDestroy(): void {
    sessionStorage.clear();
    this.store.dispatch(setPlan({} as Plan));
    this.router.navigate(['/']);
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = '';
  }

  canDeactivate(): boolean {
    const userConfirmed = confirm('Sind Sie sicher, dass Sie diese Seite verlassen möchten? Die hier angezeigten Daten gehen verloren.');
    this.pageGuard.setNavigatedFromValidUrl(userConfirmed);
    return userConfirmed;
  }
  
}
