import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContainerComponent } from '@oneqrew-partnertool/container';
import { selectPlan } from '@oneqrew-partnertool/core';
import { ListItemComponent } from '@oneqrew-partnertool/list-item';
import { Plan } from '@oneqrew-partnertool/models';
import { TagComponent } from '@oneqrew-partnertool/tag';
import { PlanCalculatorService, SharedModule } from '@oneqrew-partnertool/utils';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-new-contract',
  standalone: true,
  imports: [SharedModule, ContainerComponent, TagComponent, ListItemComponent],
  templateUrl: './new-contract.component.html',
  styleUrl: './new-contract.component.scss'
})
export class NewContractComponent implements OnInit {
  class: string = 'new-contract';
  plan$!: Observable<Plan>;

  constructor(public planCalculatorService: PlanCalculatorService, private store: Store) {}

  ngOnInit(): void {
    this.plan$ = this.store.select(selectPlan);
  }
}
