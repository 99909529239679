import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BsslegacyLegacyModule, CustomerLegacySubscription, CustomerService } from '@oneqrew-partnertool/api';
import { ContainerComponent } from '@oneqrew-partnertool/container';
import { setCustomer, setPlan } from '@oneqrew-partnertool/core';
import { Customer, LegacyModule } from '@oneqrew-partnertool/models';
import { PrimeNgModule } from '@oneqrew-partnertool/primeng';
import { PageGuard } from '@oneqrew-partnertool/utils';
import { map, switchMap } from 'rxjs';
@Component({
  selector: 'app-auth-page',
  standalone: true,
  imports: [CommonModule, PrimeNgModule, FormsModule, ContainerComponent],
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent implements OnInit {
  pageTitle: string = 'Umstellung shp - Zugang';
  customerCode!: string;
  customer!: Customer;
  plan!: any;
  codeError = false;
  notFound = false;
  codeIncomplete = false;
  loading = false;

  constructor(
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private customerService: CustomerService,
    private pageGuard: PageGuard
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.pageTitle);
    if (sessionStorage.getItem('customer') || sessionStorage.getItem('plan')) {
      sessionStorage.clear();
    }

    this.store.dispatch(setPlan(this.plan));

    this.route.queryParams.subscribe((params) => {
      const customerCodeParam = params['customerCode'];
      if (customerCodeParam) {
        this.customerCode = customerCodeParam;
        this.handleClick();
      }
    });
  }

  handleClick() {
    const formattedCustomerCode = this.formatCustomerCodeHyphens(this.customerCode);

    if (this.isValidCustomerCode(this.customerCode)) {
      this.getCustomerByUniqueKey(formattedCustomerCode);
      this.codeIncomplete = false;
    } else {
      this.codeIncomplete = true;
    }
  }

  private formatCustomerCodeHyphens(customerCode: string): string {
    return customerCode.replace(/(\w{4})(?=\w)/g, '$1-');
  }

  getCustomerByUniqueKey(uniqueKey: string): void {
    this.loading = true;
    this.notFound = false;

    this.customerService
      .getCustomerByUniqueKeyCustomerCustomer(uniqueKey)
      .pipe(
        switchMap((customerData) =>
          this.customerService
            .getAllByUniqueKeyCustomerLegacySubscription(100, 0, uniqueKey)
            .pipe(map((legacySubscription: any) => ({ customerData, legacySubscription })))
        )
      )
      .subscribe({
        next: ({ customerData, legacySubscription }) => {
          if (this.notFound) {
            this.codeError = true;
            this.loading = false;
            return;
          }
          const contractEndsAt =
            legacySubscription.find((subscription: { contractEndsAt: string }) => subscription?.contractEndsAt)?.contractEndsAt || '';
          const contractRenewal =
            legacySubscription.find((subscription: { contractRenewal: string }) => subscription?.contractRenewal)?.contractRenewal || '';

          const updatedCustomer = {
            ...this.mapModules(this.mapCustomerData(customerData), legacySubscription),
            contractEndsAt,
            contractRenewal
          };

          this.store.dispatch(setCustomer(updatedCustomer));
          this.codeError = false;
          this.loading = false;
        },
        error: () => {
          this.codeError = true;
          this.loading = false;
          this.notFound = true;
        },
        complete: () => {
          this.pageGuard.setNavigatedFromValidUrl(true);
          this.router.navigate([`/umstellung/${this.formatCustomerCode(this.customerCode)}`]);
        }
      });
  }

  private mapCustomerData(customerData: any): Customer {
    return {
      ...this.customer,
      customerCode: this.customerCode,
      id: customerData.id,
      contactId: customerData?.contactDetails?.id,
      knr: customerData?.knr ?? '',
      salutation: customerData?.contactDetails?.salutation ?? '',
      salutationOpener: customerData?.contactDetails?.salutationOpener ?? '',
      firstName: customerData?.contactDetails?.firstName ?? '',
      bsNumber: customerData?.contactDetails?.bsNumber ?? '',
      lastName: customerData?.contactDetails?.lastName ?? '',
      company: customerData?.contactDetails?.companyName ?? '',
      street: customerData?.contactDetails?.street ?? '',
      addressTwo: customerData?.contactDetails?.addressTwo ?? '',
      postCode: customerData?.contactDetails?.postalCode || '',
      city: customerData?.contactDetails?.city ?? '',
      phoneNr: customerData?.contactDetails?.phoneNr ?? '',
      mobilePhoneNr: customerData?.contactDetails?.mobilePhoneNr ?? '',
      legacyProductName: customerData?.legacyProductName ?? '',
      email: customerData?.contactDetails?.email ?? '',
      salesPartner: customerData?.salesPartner ?? null
    };
  }

  private mapModules(customerData: Customer, legacySubscription: CustomerLegacySubscription[]): Customer {
    const functionModules = legacySubscription
      .filter((subs) => {
        const module = subs.legacyModule as unknown as BsslegacyLegacyModule;
        return module?.isFunction;
      })
      .map((functionModule) => {
        const module = functionModule.legacyModule as unknown as BsslegacyLegacyModule;
        return {
          name: module?.name,
          amount: functionModule.amount,
          moduleNumber: module?.moduleNumber
        } as LegacyModule;
      })
      .filter((module, index, self) => index === self.findIndex((m) => m.name === module.name));

    const modules = legacySubscription
      .filter((subs) => {
        const module = subs.legacyModule as unknown as BsslegacyLegacyModule;
        return !module?.isFunction;
      })
      .map((module) => {
        const legacyModule = module.legacyModule as unknown as BsslegacyLegacyModule;

        return {
          name: legacyModule?.name,
          amount: module.amount
        } as LegacyModule;
      });

    const mappedCustomerData = {
      ...customerData,
      functionModules,
      modules
    };

    return mappedCustomerData;
  }

  isValidCustomerCode(value: string | undefined): boolean {
    const sanitizedValue = value?.replace(/-/g, '');
    return !!sanitizedValue && sanitizedValue.length === 16;
  }

  formatCustomerCode(value: string): string {
    return (
      value
        .replace(/-/g, '')
        .match(/.{1,4}/g)
        ?.join('-') || ''
    );
  }
}
