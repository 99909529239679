import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { Customer } from '@oneqrew-partnertool/models';
import { TagComponent } from '@oneqrew-partnertool/tag';
import { handlePlanCheck, PageGuard, RouteParam, SharedModule } from '@oneqrew-partnertool/utils';
import { Observable, of } from 'rxjs';
import * as contactData from '../../../../../shared-assets/data/shared/contact-banner.json';
@Component({
  selector: 'lib-contact-cta',
  standalone: true,
  imports: [RouterModule, SharedModule, TagComponent],
  templateUrl: './contact-cta.component.html',
  styleUrl: './contact-cta.component.scss'
})
export class ContactCtaComponent implements OnInit {
  class = 'contact-cta';
  contactData = contactData;
  userID = '';
  customer$!: Observable<Customer>;
  @Input() customer!: Customer;

  constructor(public pageGuard: PageGuard, private router: Router, private store: Store, private route: ActivatedRoute) {}

  ngOnInit(): void {
    handlePlanCheck(
      this.store,
      () => {
        this.userID = RouteParam.getParamAsString(this.route, 'id');
      },
      () => {
        sessionStorage.clear();
        this.router.navigate(['/']);
      }
    );

    this.customer$ = of(this.customer);
  }
}
