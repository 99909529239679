import { Routes } from '@angular/router';
import {
  AuthPageComponent,
  CheckoutPageComponent,
  ContactPageComponent,
  FrontPageComponent,
  OrderPageComponent,
  SuccessPageComponent
} from '@oneqrew-partnertool/pages';
import { ConfirmExit, CustomerResolver, InitailPlansResolver, PageGuard, PlanResolver } from '@oneqrew-partnertool/utils';

export const appRoutes: Routes = [
  { path: 'umstellung', component: AuthPageComponent, data: { isSubPage: false } },
  {
    path: 'umstellung/contact/:id',
    component: ContactPageComponent,
    canActivate: [PageGuard],
    data: { isSubPage: true }
  },
  {
    path: 'umstellung/order/:id',
    component: OrderPageComponent,
    canActivate: [PageGuard],
    data: { isSubPage: true, isReloadablePage: true }
  },
  {
    path: 'umstellung/checkout/:id',
    component: CheckoutPageComponent,
    canActivate: [PageGuard],
    data: { isSubPage: true }
  },
  {
    path: 'umstellung/success/:id',
    component: SuccessPageComponent,
    canDeactivate: [ConfirmExit],
    canActivate: [PageGuard],
    data: { isSubPage: false }
  },
  {
    path: 'umstellung/:id',
    component: FrontPageComponent,
    data: { isSubPage: false, isReloadablePage: true },
    resolve: {
      plans: InitailPlansResolver,
      plan: PlanResolver,
      customer: CustomerResolver
    },
    canActivate: [PageGuard]
  },
  { path: '**', redirectTo: '/umstellung', pathMatch: 'full' }
];
