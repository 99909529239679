import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, NavigationCancel, NavigationError, NavigationStart, Router } from '@angular/router';
import { HeaderComponent } from '@oneqrew-partnertool/header';
import { FooterComponent } from '@oneqrew-partnertool/footer';

@Component({
  selector: 'lib-base',
  standalone: true,
  imports: [CommonModule, HeaderComponent, FooterComponent],
  templateUrl: './base.component.html',
  styleUrl: './base.component.scss'
})
export class BaseComponent {
  @Input() isSubPage = false;
  loading = false;

  constructor(private router: Router) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        this.loading = true;
      }
      if (ev instanceof NavigationEnd || ev instanceof NavigationCancel || ev instanceof NavigationError) {
        this.loading = false;
      }
    });
  }
}
