import { ActivatedRoute } from '@angular/router';

export class RouteParam {

  /**
   * Extracts the route parameter as a string.
   * @param route The activated route containing the snapshot.
   * @param paramName The name of the parameter to extract.
   * @returns The string value of the route parameter, or an empty string if it is not present.
   */
  static getParamAsString(route: ActivatedRoute, paramName: string): string {
    const paramValue = route.snapshot.paramMap.get(paramName);
    return paramValue ? paramValue : '';
  }
}