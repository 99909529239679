import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Plan } from '@oneqrew-partnertool/models';
import { selectPlan } from '@oneqrew-partnertool/core';

@Injectable({
  providedIn: 'root'
})
export class PlanResolver implements Resolve<Plan | null> {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Plan | null> {
    return this.store.select(selectPlan).pipe(
      filter((plan) => !!plan), // Ensure a valid Plan is emitted
      take(1) // Complete after one valid emission
    );
  }
}
