import { createReducer, on } from '@ngrx/store';
import { SalesPartner } from '@oneqrew-partnertool/models';
import { setFetchedSalesPartners } from './sales-partners.action';

export interface SalesPartnersState {
  fetchedSalesPartners: SalesPartner[];
}

const initState: SalesPartnersState = {
  fetchedSalesPartners: []
};

export const salesPartnersReducer = createReducer(
  initState,
  on(setFetchedSalesPartners, (state, { salesPartners }) => {
    return {
      ...state,
      fetchedSalesPartners: salesPartners
    };
  })
);
