<form [formGroup]="orderSummaryFormGroup">
  <div [ngClass]="class" *ngIf="plan$ | async as plan">
    <h4 class="mb-1">{{ orderSummaryData['orderOverview'] }}</h4>
    <p class="text-dark-grey mb-5">
      Wunschtermin Vertragsumstellung:
      <span class="text-primary-color text-bold">{{ plan.startDate | customDateFormat }}</span>
    </p>

    <div class="{{ class + '__plan text-medium' }}">
      <div class="flex justify-content-between mb-2">
        <p class="big-text">Gewähltes Paket</p>
        <p class="text-medium">Preis/Monat</p>
      </div>
      <div class="flex justify-content-between mb-2">
        <p class="text-dark-grey text-medium">
          {{ plan.planType }}
        </p>
        <p class="flex-shrink-0 text-medium">{{ plan.price | euroCurrency }}</p>
      </div>
      <p class="mb-3 pb-1 text-dark-grey-2 {{ class }}__basic-module">
        Grundmodul
        <span class="text-primary-color">{{ plan.planType | basicModuleText }}</span>
      </p>

      <div [ngClass]="class + '__addons'">
        <div *ngFor="let module of plan.additionalModules">
          <div
            *ngIf="planCalculatorService.getModuleCalculatedPrice(module.price, module.name, plan.workStationCount, plan.planType)"
            class="flex justify-content-between"
          >
            <p class="text-dark-grey text-medium mb-2">
              {{ module.name }}
            </p>
            <p *ngIf="module" class="text-medium">
              {{
                planCalculatorService.getModuleCalculatedPrice(module.price, module.name, plan.workStationCount, plan.planType)
                  | euroCurrency
              }}
            </p>
          </div>
        </div>
      </div>

      <div *ngIf="plan.clientsCount" class="flex justify-content-between mb-2">
        <p class="text-medium">
          <span class="text-primary-color text-bold">{{ plan.clientsCount }}</span>
          {{ plan.clientsCount | pluralize : 'client' }}
        </p>
        <p class="text-medium">{{ planCalculatorService.getClientsCost(plan.clientsCount) | euroCurrency }}</p>
      </div>

      <div *ngIf="plan.workStationCount" class="flex justify-content-between">
        <p class="text-medium">
          <span class="text-primary-color text-bold">{{ plan.workStationCount }}</span>
          {{ plan.workStationCount | pluralize : 'workStation' }}
        </p>
        <p class="text-medium">{{ planCalculatorService.getTotalWorkStationCostWithModule(plan) | euroCurrency }}</p>
      </div>
    </div>

    <div class="{{ class + '__subtotal' }}">
      <div class="flex justify-content-between">
        <p class="">{{ orderSummaryData['subtotalTitle'] }}</p>
        <p class="text-primary-color text-bold">
          {{ planCalculatorService.getSubtotal(plan) | euroCurrency }}
        </p>
      </div>
    </div>
    <div class="{{ class + '__rebate mb-2' }}">
      <div class="flex justify-content-between">
        <p class="text-primary-color">{{ orderSummaryData['rebateTitle'] }}</p>
        <p class="text-dark-grey text-bold">-{{ planCalculatorService.getRebate(plan) | euroCurrency }}</p>
      </div>
    </div>

    <div *ngIf="plan.cloudVersion" class="flex justify-content-between mb-2">
      <p class="text-medium">
        <span class="text-primary-color text-bold">Cloud Version</span>
      </p>
      <p class="text-medium text-primary-color">+{{ planCalculatorService.getCloudVersionCost(plan.cloudVersion) | euroCurrency }}</p>
    </div>

    <div *ngIf="plan.devicesCount" class="flex justify-content-between mb-2">
      <p class="text-medium">
        <span class="text-primary-color text-bold">{{ plan.devicesCount }}</span>
        {{ plan.devicesCount | pluralize : 'device' }}
      </p>
      <p class="text-medium text-primary-color">+{{ planCalculatorService.getTotalDeviceCost(plan.devicesCount) | euroCurrency }}</p>
    </div>

    <div class="mb-5">
      <div class="flex justify-content-between mb-1">
        <h4>{{ orderSummaryData['totalTitle'] }}</h4>
        <h4>{{ planCalculatorService.getTotal(plan) | euroCurrency }}</h4>
      </div>

      <p class="small-text text-dark-grey-2">Alle genannten Preise verstehen sich exklusive Mehrwertsteuer.</p>
    </div>

    <div class="mb-4">
      <div class="{{ class + '__checkbox' }}">
        <p-checkbox formControlName="consent" binary="true" inputId="consent"></p-checkbox>
        <label for="consent" class="{{ class + '__checkbox-text required' }}" [innerHTML]="orderSummaryData['consentText']"></label>
      </div>

      <span
        class="small-text block text-highlight"
        *ngIf="orderSummaryFormGroup.get('consent')?.invalid && orderSummaryFormGroup.get('consent')?.touched"
      >
        {{ getErrorMessage('consent') }}
      </span>
    </div>

    <div class="mb-4">
      <div class="{{ class + '__checkbox' }}">
        <p-checkbox formControlName="terms" binary="true" inputId="terms"></p-checkbox>
        <label for="terms" class="{{ class + '__checkbox-text required' }}" [innerHTML]="orderSummaryData['termsLabel']"></label>
      </div>

      <span
        class="small-text block text-highlight"
        *ngIf="orderSummaryFormGroup.get('terms')?.invalid && orderSummaryFormGroup.get('terms')?.touched"
      >
        {{ getErrorMessage('terms') }}
      </span>
    </div>

    <p class="text-dark-grey-2 mb-4">{{ orderSummaryData['hint'] }}</p>

    <div class="{{ class + '__recaptch flex mb-6' }}">
      <re-captcha (resolved)="resolved($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
      <span class="required"></span>
      <span
        class="small-text block text-highlight"
        *ngIf="orderSummaryFormGroup.get('recaptcha')?.invalid && orderSummaryFormGroup.get('recaptcha')?.touched"
      >
        {{ getErrorMessage('recaptcha') }}
      </span>
    </div>

    <div class="mb-5">
      <lib-button
        [title]="orderSummaryData['orderButton']"
        bStyle="solid"
        icon="pi-arrow-right"
        type="submit"
        (onClick)="onHandleClick()"
      ></lib-button>
    </div>

    <div class="mb-3">
      <lib-button
        [title]="customer.salesPartner.contactDetails?.companyName + ' kontaktieren'"
        bStyle="outline"
        type="button"
        (onClick)="onClickSupport()"
      ></lib-button>
    </div>

    <p class="text-dark-grey font-light">
      {{ orderSummaryData['orderHelpText'] }}
    </p>
  </div>
</form>
