import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  BasicModuleTextPipe,
  CustomDateFormatPipe,
  EuroCurrencyPipe,
  ModuleFormatPipe,
  PerMonthPipe,
  PluralizePipe
} from '@oneqrew-partnertool/pipes';

@NgModule({
  declarations: [EuroCurrencyPipe, PerMonthPipe, CustomDateFormatPipe, PluralizePipe, BasicModuleTextPipe, ModuleFormatPipe],
  imports: [CommonModule, ReactiveFormsModule, RouterModule],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    EuroCurrencyPipe,
    PerMonthPipe,
    CustomDateFormatPipe,
    PluralizePipe,
    BasicModuleTextPipe,
    ModuleFormatPipe
  ]
})
export class SharedModule {}
