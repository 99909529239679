<lib-order-overview
  [heading]="headingTemplate"
  [leftContent]="leftContentTemplate"
  [rightContent]="rightContentTemplate"
></lib-order-overview>
<ng-template #headingTemplate>
  <h2>SEPA Lastschrift Mandat</h2>
  <p>Bitte überprüfen und ergänzen Sie alle Angaben.</p>
</ng-template>

<ng-template #leftContentTemplate>
  <form [formGroup]="checkoutForm" (ngSubmit)="onFormSubmit()">
    <lib-order-contact (contactForm$)="formInitialized($event)" [tag]="true" tagSize="s" [isCheckoutPage]="true"></lib-order-contact>
  </form>
</ng-template>

<ng-template #rightContentTemplate>
  <form [formGroup]="checkoutForm" (ngSubmit)="onFormSubmit()" autocomplete="on">
    <lib-bank-details [loading]="loading" (bankDetailsForm)="formInitialized($event)" (onSubmit)="onFormSubmit()"></lib-bank-details>
  </form>

  <p *ngIf="loading" class="mt-2 text-right">
    Anfrage senden
    <i class="pi pi-spin pi-spinner"></i>
  </p>
  <p *ngIf="errorSending" class="mt-2 small-text text-red">
    Beim Senden Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.
  </p>
</ng-template>
