<form [formGroup]="bankDetailsFormGroup" autocomplete="creditCard">
  <div [ngClass]="class">
    <div [ngClass]="class + '__header text-primary-color'">
      <h4>Bankverbindung</h4>

      <div class="col-12">
        <div [ngClass]="class + '__field'">
          <label class="required" for="cardHolder">{{ fieldLabelData.cardHolder }}</label>
          <input id="cardHolder" pInputText formControlName="cardHolder" autocomplete="name" />
        </div>

        <span
          class="small-text block text-highlight"
          *ngIf="bankDetailsFormGroup.get('cardHolder')?.invalid && bankDetailsFormGroup.get('cardHolder')?.touched"
        >
          {{ getErrorMessage('cardHolder') }}
        </span>
      </div>

      <div class="col-12">
        <div [ngClass]="class + '__field'">
          <label class="required" for="iban">{{ fieldLabelData.iban }}</label>
          <input
            id="iban"
            pInputText
            placeholder="e.g., DE89 3704 0044 0532 0130 00"
            formControlName="iban"
            autocomplete="IBAN"
            (focus)="revertIbanFormat()"
            (input)="formatInputIban($event)"
          />
        </div>

        <span
          class="small-text block text-highlight"
          *ngIf="bankDetailsFormGroup.get('iban')?.invalid && bankDetailsFormGroup.get('iban')?.touched"
        >
          {{ getErrorMessage('iban') }}
        </span>
      </div>

      <div class="col-12">
        <div [ngClass]="class + '__field'">
          <label for="bic">{{ fieldLabelData.bic }}</label>
          <input id="bic" pInputText formControlName="bic" autocomplete="bic" />
        </div>

        <span
          class="small-text block text-highlight"
          *ngIf="bankDetailsFormGroup.get('bic')?.invalid && bankDetailsFormGroup.get('bic')?.touched"
        >
          {{ getErrorMessage('bic') }}
        </span>
      </div>

      <div class="col-12 mb-5">
        <div [ngClass]="class + '__field'">
          <label class="required" for="creditInstitution">{{ fieldLabelData.creditInstitution }}</label>
          <input id="creditInstitution" pInputText formControlName="creditInstitution" autocomplete="organization" />
        </div>

        <span
          class="small-text block text-highlight"
          *ngIf="bankDetailsFormGroup.get('creditInstitution')?.invalid && bankDetailsFormGroup.get('creditInstitution')?.touched"
        >
          {{ getErrorMessage('creditInstitution') }}
        </span>
      </div>
    </div>

    <div class="{{ class + '__button-container' }}">
      <div class="mb-5">
        <lib-button
          title="SEPA-Mandat erteilen und Umstellung beauftragen"
          bStyle="solid"
          icon="pi-arrow-right"
          type="button"
          (onClick)="onHandleClick()"
          [disabled]="loading"
        ></lib-button>
      </div>
      <!-- 
      <div class="mb-3">
        <lib-button
          title="Kundensupport kontaktieren"
          (onClick)="onClickSupport()"
          bStyle="outline"
          icon="pi-phone"
          type="button"
        ></lib-button>
      </div> -->
    </div>
    <!-- <p class="text-dark-grey font-light">Sollten Sie Fragen zum Bestellprozess haben kontaktieren Sie unseren Kundensupport.</p> -->
  </div>
</form>
