import { createReducer, on } from '@ngrx/store';
import { Plan } from '@oneqrew-partnertool/models';
import {
  addAdditionalModule,
  removeAdditionalModule,
  setPlan
} from './plan.action';

export interface PlanState {
  plan: Plan; 
}

export const initialState: PlanState = {
  plan: new Plan(),
};

export const planReducer = createReducer(
  initialState,

  on(setPlan, (state, plan) => ({ ...state, plan })),

  on(addAdditionalModule, (state, additionalModule) => ({
    ...state,
    plan: {
      ...state.plan,
      additionalModules: [...state.plan.additionalModules || [], additionalModule],
    },
  })),

  on(removeAdditionalModule, (state, additionalModule) => ({
    ...state,
    plan: {
      ...state.plan,
      additionalModules: state.plan.additionalModules?.filter(
        (module) => module.name !== additionalModule.name
      ),
    },
  }))
);
