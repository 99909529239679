import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { setFetchedSalesPartners } from '@oneqrew-partnertool/core';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CustomerService } from '../../../../common/data-access/api-client/src';
import { SalesPartner } from '../../../models/src';
@Injectable({ providedIn: 'root' })
export class SalesPartnersResolver implements Resolve<any> {
  constructor(private customerService: CustomerService, private store: Store) {}

  resolve(): Observable<any> {
    return this.customerService.getAllCustomerSalesPartner().pipe(
      tap((fetchedSalesPartners) => {
        this.store.dispatch(setFetchedSalesPartners({ salesPartners: fetchedSalesPartners as SalesPartner[] }));
      }),
      map((fetchedSalesPartners) => fetchedSalesPartners)
    );
  }
}
