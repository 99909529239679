import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import * as orderDetailsData from '../../../../../shared-assets/data/order-details.json';
import { SharedModule } from '@oneqrew-partnertool/utils';

@Component({
  selector: 'lib-order-details',
  standalone: true,
  imports: [RouterLink, SharedModule],
  templateUrl: './order-details.component.html',
  styleUrl: './order-details.component.scss'
})
export class OrderDetailsComponent {
  class = 'order-details'
  orderDetailsData = orderDetailsData;
}
