/**
 * BlueSolution Subscription Management API
 * Last changed: 2025-03-05T10:54:50+00:00
 *
 * OpenAPI spec version: 1.0.13
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomerCustomer } from './customerCustomer';

/**
 * Current information about the switch from legacy to oneqrew subscriptions
 */
export interface CustomerConversionProcess { 
    /**
     * legacy, pending, converted
     */
    conversionStatus: string;
    mailSentAt?: string;
    linkFirstOpenedAt?: string;
    discount?: number;
    /**
     * Indicates if for a specific conversion process a pdf is already created
     */
    pdfGenerated: boolean;
    id?: number;
    customer?: CustomerCustomer;
}