import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ButtonComponent } from '@oneqrew-partnertool/button';
import { environment } from '@oneqrew-partnertool/config';
import { selectCustomer, selectPlan, setPlan } from '@oneqrew-partnertool/core';
import { Customer, Plan } from '@oneqrew-partnertool/models';
import { PrimeNgModule } from '@oneqrew-partnertool/primeng';
import { PageGuard, PlanCalculatorService, RouteParam, SharedModule } from '@oneqrew-partnertool/utils';
import { RecaptchaModule } from 'ng-recaptcha-2';
import { Observable } from 'rxjs';
import * as orderSummaryData from '../../../../../shared-assets/data/order-summary.json';
@Component({
  selector: 'lib-order-summary',
  standalone: true,
  imports: [SharedModule, PrimeNgModule, RecaptchaModule, ButtonComponent],
  templateUrl: './order-summary.component.html',
  styleUrl: './order-summary.component.scss'
})
export class OrderSummaryComponent implements OnInit {
  @Output() onSubmit = new EventEmitter<void>();
  @Output() orderSummaryForm = new EventEmitter<FormGroup>();
  class = 'order-summary';
  orderSummaryData = orderSummaryData;
  recaptchaSiteKey = environment.landingPage.recaptcha;
  userID: string = '';
  plan$!: Observable<Plan>;
  customer!: Customer;
  plan!: Plan;
  orderSummaryFormGroup!: FormGroup;
  monthlyCostSum?: number;

  constructor(
    public planCalculatorService: PlanCalculatorService,
    private store: Store,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private pageGuard: PageGuard
  ) {}

  ngOnInit(): void {
    this.plan$ = this.store.select(selectPlan);
    this.store.select(selectCustomer).subscribe((customer) => (this.customer = customer));

    this.plan$.subscribe((plan) => {
      this.monthlyCostSum = Number(this.planCalculatorService.getTotal(plan).toFixed(2));
      this.plan = plan;
    });

    let setMonthlyPrice = {
      ...this.plan,
      monthlyCostSum: this.monthlyCostSum
    };

    this.store.dispatch(setPlan(setMonthlyPrice));

    this.userID = RouteParam.getParamAsString(this.route, 'id');

    this.orderSummaryFormGroup = this.fb.group({
      consent: [false, Validators.requiredTrue],
      terms: [false, Validators.requiredTrue],
      recaptcha: [null, Validators.required]
    });

    this.orderSummaryForm.emit(this.orderSummaryFormGroup);
  }

  getErrorMessage(field: string): string {
    const control = this.orderSummaryFormGroup.get(field);

    if (control?.hasError('required') && field === 'consent') {
      return 'Bitte willigen Sie der Kontaktaufnahme und der Datenverarbeitung ein.';
    }

    if (control?.hasError('required') && field === 'terms') {
      return 'Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen, bevor Sie fortfahren.';
    }

    if (control?.hasError('required')) {
      return 'Bitte schließen Sie den CAPTCHA ab, um fortzufahren.';
    }

    return '';
  }

  onHandleClick() {
    if (this.orderSummaryFormGroup.invalid) {
      this.orderSummaryFormGroup.markAllAsTouched();
    }

    this.onSubmit.emit();
  }

  onClickSupport() {
    this.pageGuard.setNavigatedFromValidUrl(true);
    this.router.navigate(['/umstellung/contact/' + this.userID]);
  }

  resolved(captchaResponse: string | null) {
    if (captchaResponse) {
      this.orderSummaryFormGroup.get('recaptcha')?.setValue(captchaResponse);
    }
  }

  getDevicePriceDetails(plan: Plan): string {
    const deviceCost = plan.devicesCount ? ` + ${this.planCalculatorService.getTotalDeviceCost(plan.devicesCount).toFixed(2)}€` : '';
    return `${deviceCost}`;
  }

  getWorkstationPriceDetails(plan: Plan): string {
    const workstationCost = plan.workStationCount
      ? ` + ${this.planCalculatorService.getTotalWorkStationCost(plan.planType, plan.workStationCount).toFixed(2)}€`
      : '';
    return `${workstationCost}`;
  }
}
