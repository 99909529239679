import { NgModule } from '@angular/core';

// PrimeNG Components
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';

// Import other PrimeNG modules as needed

@NgModule({
  imports: [
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    CheckboxModule,
    InputNumberModule,
    CalendarModule,
    InputMaskModule,
    InputSwitchModule,
    DropdownModule
  ],
  exports: [
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    CheckboxModule,
    InputNumberModule,
    CalendarModule,
    InputMaskModule,
    InputSwitchModule,
    DropdownModule
  ]
})
export class PrimeNgModule {}
