<lib-container>
  <div class="text-center pt-5 ">
    <h2 class="my-5">
      Umstellung auf
      <span class="text-header-underlined">smarthandwerk pro</span>
    </h2>
    <div>
      <p>
        Geben Sie Ihren persönlichen Zugangscode in das untenstehende Eingabefeld ein und erhalten
      </p>
      <p> Sie Ihr passendes Angebot.</p>
    </div>
  </div>
  <div class="auth py-8">
    <div class="card flex flex-column justify-content-center">
      <label for="username">Zugangscode</label>
      <div class="flex gap-2 input">
        <div class="w-full">
          <p-inputMask inputId="username" [(ngModel)]="customerCode" mask="****-****-****-****" autoClear="false"
            unmask="true" aria-describedby="username-help" (keydown.enter)="handleClick()"></p-inputMask>

          <span *ngIf="codeError" class="small-text text-dark-orange block">
            {{ errorCode == httpStatusCode.NotFound ? 'Kundencode kann nicht gefunden werden. Bitte überprüfen Sie Ihren Code und versuchen Sie es erneut.' : 'Bei der Eingabe des Codes ist ein Serverfehler aufgetreten. Bitte versuchen Sie es später erneut. Wir arbeiten bereits an einer Lösung.' }}
          </span>

          <span *ngIf="codeIncomplete" class="small-text text-dark-orange block">
            Der Kundencode muss genau 16 Zeichen lang sein (ohne Bindestriche).
          </span>
        </div>

        <div class="button">
          <div *ngIf="loading" class="loading">
            <i class="pi pi-spin pi-spinner"></i>
          </div>
          <p-button (click)="handleClick()" icon="pi pi-arrow-right" iconPos="right" label="Bestätigen"></p-button>
        </div>
      </div>
    </div>
  </div>
</lib-container>