/**
 * BlueSolution Subscription Management API
 * Last changed: 2024-11-18T10:02:49+00:00
 *
 * OpenAPI spec version: 1.0.9
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * The contact details for a specifc customer
 */
export interface CustomerContactDetails { 
    phoneNr: string;
    email: string;
    postalCode?: string;
    street?: string;
    city?: string;
    mobilePhoneNr?: string;
    companyName?: string;
    salutation?: string;
    lastName: string;
    firstName: string;
    country?: string;
    salutationOpener?: string;
    companyCeo?: string;
    /**
     * User Id on blue solutions side
     */
    bsNumber: string;
    addressExtra?: string;
    id?: number;
}