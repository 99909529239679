import { Component, OnInit } from '@angular/core';
import { ContainerComponent } from '@oneqrew-partnertool/container';
import { SharedModule, DateFormatterService } from '@oneqrew-partnertool/utils';

@Component({
  selector: 'lib-success-message',
  standalone: true,
  imports: [ContainerComponent, SharedModule],
  templateUrl: './success-message.component.html',
  styleUrls: [
    './success-message.component.scss',
    './success-message-circle-progress.component.scss',
    './success-message-text.component.scss'
  ]
})
export class SuccessMessageComponent implements OnInit {
  class = 'success';
  dateSent!: string;

  constructor(private dateFormatterService: DateFormatterService) {}

  ngOnInit(): void {
    this.dateSent = this.dateFormatterService.formatDate(new Date());
  }
}
