import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InputSyncService {
  private devicesCountSubject = new BehaviorSubject<number>(0);
  private workStationCountSubject = new BehaviorSubject<number>(0);

  devicesCount$ = this.devicesCountSubject.asObservable();
  workStationCount$ = this.workStationCountSubject.asObservable();

  setDevicesCount(count: number) {
    this.devicesCountSubject.next(count);
  }

  getDevicesCount() {
    return this.devicesCountSubject.getValue();
  }

  setWorkStationCount(count: number) {
    this.workStationCountSubject.next(count);
  }

  getWorkStationCount() {
    return this.workStationCountSubject.getValue();
  }
}
