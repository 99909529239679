<section [ngClass]="class">
  <lib-container>
    <p [ngClass]="class + '__title h4'">
      {{ modulesData['moduleAddonTitle'] }}
    </p>

    <div *ngIf="plan$ | async as plans" [ngClass]="class + '__items'">
      <ng-container *ngFor="let item of modulesData['modules']; let i = index">
        <lib-module-card
          [id]="item.id"
          [tag]="tagList[i]"
          [name]="item?.name"
          [pricePerMonth]="item.pricePerMonth"
          [isActive]="isActive(plans, item.name)"
          (moduleToggled)="handleModuleToggled($event)"
          [disabled]="tagList[i] === baseTag && plans?.planType === 'smarthandwerk pro L-Paket'"
        ></lib-module-card>
      </ng-container>
    </div>
  </lib-container>
</section>
