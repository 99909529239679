import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { addAdditionalModule, removeAdditionalModule, selectFetchedPlans, setPlan } from '@oneqrew-partnertool/core';
import { ListItemComponent } from '@oneqrew-partnertool/list-item';
import { Customer, Module, Plan } from '@oneqrew-partnertool/models';
import { PrimeNgModule } from '@oneqrew-partnertool/primeng';
import { TagComponent } from '@oneqrew-partnertool/tag';
import { PageGuard, PlanCalculatorService, RouteParam, SharedModule } from '@oneqrew-partnertool/utils';
import { Calendar } from 'primeng/calendar';
import { take } from 'rxjs/operators';
import * as planSelectorData from '../../../../../../common/shared-assets/data/pages/front-page.json';
@Component({
  selector: 'lib-plan-selector',
  standalone: true,
  imports: [SharedModule, FormsModule, PrimeNgModule, TagComponent, ListItemComponent],
  templateUrl: './plan-selector.component.html',
  styleUrl: './plan-selector.component.scss'
})
export class PlanSelectorComponent implements OnInit {
  @ViewChild('calendar') calendar!: Calendar;
  class = 'plan-selector';
  userID: string = '';
  devicesCount!: number;
  workStationCount!: number;
  clientsCount!: number;
  cloudVersion?: boolean;
  total!: number;
  startDate!: Date;
  minDate!: Date;
  resetDate!: Date;
  noResetDate: boolean = false;
  startDateError = false;
  disableModules: any[] = [];
  frontPage = planSelectorData;
  @Input() plan!: Plan;
  @Input() isSubscriptionPlans = false;
  @Input() company = '';
  @Input() tag = '';
  @Input() disclaimer = '';
  @Input() activePlan!: Plan;
  @Input() customer!: Customer;
  @Input() isNewCustomer: Boolean = false;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    public planCalculatorService: PlanCalculatorService,
    private pageGuard: PageGuard
  ) {}

  ngOnInit(): void {
    this.userID = RouteParam.getParamAsString(this.route, 'id');
    this.minDate = new Date();
    this.startDate = this.plan.startDate;

    this.initializeCounts();

    const updatedPlan = { ...this.plan, devicesCount: this.devicesCount, workStationCount: this.workStationCount };
    if (!this.isSubscriptionPlans) {
      if (this.customer.contractEndsAt) {
        this.resetDate = new Date(this.customer.contractEndsAt);
      }

      this.setActivePlan(updatedPlan);
    }

    this.store
      .select(selectFetchedPlans)
      .pipe(take(1))
      .subscribe((fetchedPlans) => {
        if (!fetchedPlans || fetchedPlans.length === 0) {
          console.warn('Fetched Plans are empty. Ensure the store is updated before this component initializes.');
        } else {
          const selectedPlan = fetchedPlans[1];
          if (selectedPlan && selectedPlan.additionalModules) {
            this.disableModules = selectedPlan.additionalModules.map((mod: any) => mod.name.trim());
          } else {
            console.warn('No additionalModules found in the selected plan.');
          }
        }
      });

    this.total = this.planCalculatorService.getTotal(this.plan);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['plan'] && changes['plan'].currentValue !== changes['plan'].previousValue) {
      this.initializeCounts();
    }
  }

  initializeCounts(): void {
    if (this.plan) {
      this.clientsCount = this.plan.clientsCount;
      this.devicesCount = this.plan.devicesCount;
      this.workStationCount = this.plan.workStationCount;
      this.cloudVersion = this.plan.cloudVersion;
    }
  }

  // Calendar Button
  handleDateSelect(event: any): void {
    this.startDate = event;

    const updatedPlan = {
      ...this.plan,
      startDate: this.startDate
    };

    this.setActivePlan(updatedPlan);
    this.noResetDate = false;
    this.startDateError = false;
  }

  formatInputDate(event: any): void {
    this.noResetDate = false;
    this.calendar.hideOverlay();
    // Replace '/' with '.' in the input value without assigning it to startDate
    event.target.value = event.target.value.replace(/\//g, '.');
    event.target.value = event.target.value
      .replace(/^(\d{2})(\d{2})(\d{4})$/, '$1.$2.$3') // Insert dots after day and month if 8 digits are present
      .replace(/^(\d{2})(\d{2})/, '$1.$2.'); // Insert dots after day and month as you type

    if (event.inputType === 'insertFromPaste') {
      this.startDate = event.target.value;
    }
  }

  setCurrentDate() {
    this.startDate = this.minDate;
    this.calendar.hideOverlay();
  }

  // Main Button
  handleButtonClick(plan: Plan): void {
    if (this.isSelectedPlan) return;

    const updatedAdditionalModules = plan.additionalModules?.map((module) => ({
      ...module,
      price: module.price ?? (module as any).monthlyPrice
    }));

    const updatedPlan = {
      ...plan,
      startDate: this.startDate,
      additionalModules: updatedAdditionalModules,
      devicesCount: this.plan.devicesCount,
      workStationCount: this.plan.workStationCount
    };

    this.setActivePlan(updatedPlan);

    if (!this.startDate) {
      this.startDateError = true;
    }

    if (!this.isSubscriptionPlans && !this.startDateError) {
      this.pageGuard.setNavigatedFromValidUrl(true);
      this.router.navigate(['umstellung/order/', this.userID]);
    } else {
      this.scroll();
    }

    this.setActivePlan(updatedPlan);
  }
  @ViewChild('target') target!: ElementRef;

  scroll(): void {
    const element = document.querySelector(`.not-plan-subscriptions`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // Text Condtional Displays
  get buttonLabel(): string {
    if (!this.isSubscriptionPlans) {
      return this.frontPage.planSelector.buttonLabel.notSubscriptionPlan.existingCustomer;
    }
    return this.isSelectedPlan
      ? this.frontPage.planSelector.buttonLabel.selectedPlan
      : this.frontPage.planSelector.buttonLabel.unselectedPlan;
  }

  get isSelectedPlan(): boolean {
    return this.plan?.planType === this.activePlan?.planType;
  }

  // Handle Count Changes
  handleDevicesCountChange(newCount: number): void {
    this.devicesCount = newCount;
    let updatedDevicesCount = {
      ...this.plan,
      devicesCount: this.devicesCount
    };
    this.setActivePlan(updatedDevicesCount);
  }

  handleWorkStationCountChange(newCount: number): void {
    this.workStationCount = newCount;
    let updatedWorkStationCount = {
      ...this.plan,
      workStationCount: this.workStationCount
    };
    this.setActivePlan(updatedWorkStationCount);
  }

  handleClientsCountChange(newCount: number): void {
    this.clientsCount = newCount;
    let updatedClientsCount = {
      ...this.plan,
      clientsCount: this.clientsCount
    };
    this.setActivePlan(updatedClientsCount);
  }

  handleCloudVersionChange(toggle: boolean): void {
    this.cloudVersion = toggle;

    let updatedCloudVersion = {
      ...this.plan,
      cloudVersion: this.cloudVersion
    };
    this.setActivePlan(updatedCloudVersion);
  }

  // Modules
  isActiveModule(moduleName: string): boolean {
    // Check if module is active in the current plan
    return !!this.plan.additionalModules?.map((module) => module.name)?.includes(moduleName);
  }

  handleListItemAction(id: number, moduleName: string, price: number): void {
    const isActive = this.isActiveModule(moduleName);

    const module: Module = { id: id, name: moduleName, price: price };

    if (isActive) {
      this.removeModuleFromStore(module);
    } else {
      this.addModuleToStore(module);
    }
  }

  // Store Handlers
  setActivePlan(plan: Plan): void {
    this.store.dispatch(setPlan(plan));
  }

  addModuleToStore(module: Module) {
    this.store.dispatch(addAdditionalModule(module));
  }

  removeModuleFromStore(module: Module) {
    this.store.dispatch(removeAdditionalModule(module));
  }
}
