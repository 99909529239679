import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PageGuard implements CanActivate {
  private navigatedFromValidUrl = false;

  constructor(private router: Router) {}

  setNavigatedFromValidUrl(value: boolean) {
    this.navigatedFromValidUrl = value;
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const session = JSON.parse(sessionStorage.getItem('customer') as string);

    if (this.navigatedFromValidUrl || (session.customer.customerCode && route.data['isReloadablePage'])) {
      this.navigatedFromValidUrl = false; // Reset after accessing
      return true;
    }
    // Navigate to homepage if not accessed correctly
    this.router.navigate(['/']);
    return false;
  }
}
