<footer [ngClass]="class">
  <lib-container [isWide]="true" class="text-white">
    <a [routerLink]="['/']">
      <img src="assets/img/site-logo-white.svg" alt="Site Logo" width="409.41" height="56" [ngClass]="class + '__logo'" />
    </a>

    <div [ngClass]="class + '__content'">
      <div [ngClass]="class + '__contact'">
        <h4>{{ footerData['contactTitle'] }}</h4>
        <p class="text-white mb-2" [innerHTML]="footerData['address']"></p>
        <p class="text-white mb-2">
          <a [href]="footerData['contactLinks'][0].phone.link" target="_blank">
            {{ footerData['contactLinks'][0].phone.title }}
          </a>
        </p>
        <p class="text-white">
          <a [href]="footerData['contactLinks'][0].email.link" target="_blank">
            {{ footerData['contactLinks'][0].email.title }}
          </a>
        </p>
      </div>

      <div [ngClass]="class + '__data-link'">
        <h4>Rechtliches</h4>
        <ul [ngClass]="class + '__links'">
          <li *ngFor="let dataLink of footerData['dataLinks']">
            <a [href]="dataLink['link']" target="_blank">{{ dataLink['title'] }}</a>
          </li>
        </ul>
      </div>
    </div>

    <a href="https://bluesolution.de/" [ngClass]="class + '__oneqrew'" target="_blank">
      <p class="small-text text-white">/ Wir sind</p>

      <img src="assets/img/oneqrew-logo.svg" alt="OneQrew" width="77.24" height="41.29" />
    </a>
  </lib-container>
</footer>
