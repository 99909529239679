import { Component } from '@angular/core';
import { NewContractComponent } from '@oneqrew-partnertool/new-contract';
import { SuccessMessageComponent } from "@oneqrew-partnertool/success-message";

@Component({
  selector: 'lib-order-success',
  standalone: true,
  imports: [NewContractComponent, SuccessMessageComponent],
  templateUrl: './order-success.component.html',
})
export class OrderSuccessComponent {
  class = 'order-success';
}
