<lib-header [isSubPage]="isSubPage"></lib-header>
<main>
  <div *ngIf="loading" class="loading">
    <h1>Loading...</h1>
    &nbsp;
    <i class="pi pi-spin pi-spinner"></i>
  </div>
  <ng-content *ngIf="!loading"></ng-content>
</main>
<lib-footer></lib-footer>
