import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moduleFormat'
})
export class ModuleFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    let parts = value.split(' ');
    let amount = parts.shift(); // Get the first element (amount)
    let name = parts.join(' '); // Join the remaining words

    // Define mappings for singular and plural forms
    const mappings: { [key: string]: { singular: string; plural: string } } = {
      'mobiler Geräte': { singular: 'mobiles Gerät', plural: 'mobile Geräte' },
      'Mandant': { singular: 'Mandant', plural: 'Mandanten' },
      'Arbeitsplätze': { singular: 'Arbeitsplatz', plural: 'Arbeitsplätze' }
    };

    // Remove "Anzahl" from the name
    name = name.replace(/^Anzahl /, '');

    // Apply correct singular/plural forms
    const mapping = Object.keys(mappings).find(key => name.includes(key));
    if (mapping) {
      name = amount === '1' ? mappings[mapping].singular : mappings[mapping].plural;
    }

    // If amount is 1, replace it with "Ein"
    if (amount === '1') {
      return `Ein ${name}`;
    }
    
    return `${amount} ${name}`;
  }
}
