<lib-order-overview
  [heading]="headingTemplate"
  [leftContent]="leftContentTemplate"
  [rightContent]="rightContentTemplate"
></lib-order-overview>

<ng-template #headingTemplate>
  <h2>Alle Daten im Überblick</h2>
  <p>Bitte überprüfen und ergänzen Sie alle Angaben.</p>
</ng-template>

<ng-template #leftContentTemplate>
  <form [formGroup]="orderForm" (ngSubmit)="onFormSubmit()">
    <lib-order-contact (contactForm$)="formInitialized($event)" [isNewCustomer]="isNewPlanPage">></lib-order-contact>
    <lib-order-customer-info (contactForm$)="formInitialized($event)" *ngIf="isNewPlanPage"></lib-order-customer-info>
    <lib-order-details></lib-order-details>
  </form>
</ng-template>

<ng-template #rightContentTemplate>
  <form [formGroup]="orderForm" (ngSubmit)="onFormSubmit()">
    <lib-order-summary (orderSummaryForm)="formInitialized($event)" (onSubmit)="onFormSubmit()"  [isNewCustomer]="isNewPlanPage" [orderFormValid]="orderFormValid"></lib-order-summary>
  </form>
</ng-template>

<lib-faqs [isPaddingY]="true" *ngIf="!isNewPlanPage"></lib-faqs>
