<div *ngIf="!button" class="list-item">
  <ng-container *ngTemplateOutlet="listTemplate"></ng-container>
</div>

<button *ngIf="button" class="list-item" (click)="handleClick()" [disabled]="disabled">
  <ng-container *ngTemplateOutlet="listTemplate"></ng-container>
</button>

<ng-template #listTemplate>
  <img src="{{ 'assets/img/icons/' + theIcon + '.svg' }}" alt="Checkbox" />

  <p>{{ text }}</p>
</ng-template>
