import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { OneqrewService } from '@oneqrew-partnertool/api';
import { selectCustomer } from '@oneqrew-partnertool/core';
import { Customer } from '@oneqrew-partnertool/models';

export const INITIAL_PLANS = [
  {
    planType: 'smarthandwerk pro M-Paket',
    discount: 30,
    cloudVersion: false,
    packageType: 'pro-m'
  },
  {
    planType: 'smarthandwerk pro L-Paket',
    discount: 30,
    cloudVersion: false,
    packageType: 'pro-l'
  }
] as any[];

@Injectable({
  providedIn: 'root'
})
export class PlanDataService {
  customer!: Customer;

  constructor(private oneqrewService: OneqrewService, private store: Store) {}

  private getModuleCount(moduleName: string): number {
    return this.customer?.functionModules?.find((fm) => fm.name?.includes(moduleName))?.amount || 0;
  }

  getInitialPlans(): Observable<any[]> {
    return this.store.select(selectCustomer).pipe(
      take(1),
      switchMap((storedCustomer: Customer | null) => {
        if (!storedCustomer) {
          console.warn('Customer data not found in store.');
          return of([]); // Return an empty array if no customer data is available
        }

        this.customer = storedCustomer;

        // Prepare observables for each plan
        const planObservables = INITIAL_PLANS.map((plan) => {
          // Calculate devicesCount based on packageType
          let devicesCount = Math.max(0, this.getModuleCount('Geräte'));
          if (plan.packageType === 'pro-l') {
            devicesCount = Math.max(0, devicesCount - 2);
          }
          // Calculate workStationCount and clientsCount
          const workStationCount = Math.max(0, this.getModuleCount('Arbeitsplätze') - 1);
          const clientsCount = Math.max(0, this.getModuleCount('mandant'));

          const updatedPlan = {
            ...plan,
            devicesCount,
            workStationCount,
            clientsCount
          };

          return this.oneqrewService.getProductByPackageTypeOneqrewProductPackage(plan.packageType).pipe(
            switchMap((productPackage) => {
              const price = productPackage?.monthlyPrice ?? 0;
              const planWithPrice = { ...updatedPlan, price };

              return this.oneqrewService.getModulesInProductPackageOneqrewModule(plan.packageType).pipe(
                map((modules) => {
                  const additionalModules = modules.filter((module) => module.isExtra).map(({ isExtra, ...module }) => module);
                  const regularModules = modules
                    .filter((module) => !module.isExtra && module.name !== 'Grundmodul')
                    .map(({ isExtra, ...module }) => module);
                  return { ...planWithPrice, modules: regularModules, additionalModules };
                })
              );
            }),
            catchError((error) => {
              console.error(`Error fetching data for ${plan.packageType}:`, error);
              return of({ ...updatedPlan, price: 0, modules: [], additionalModules: [] });
            })
          );
        });

        return forkJoin(planObservables);
      })
    );
  }
}
