import { CommonModule, Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ContainerComponent } from '@oneqrew-partnertool/container';
import { PageGuard } from '@oneqrew-partnertool/utils';
@Component({
  selector: 'lib-header',
  standalone: true,
  imports: [RouterLink, CommonModule, ContainerComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  class = 'header';
  userID: string = '';

  @Input() isSubPage = false;
  constructor(private router: Router, private pageGuard: PageGuard, private location: Location) {}

  handleClick(): void {
    if (window.history.length > 1 && this.isSubPage) {
      const currentUrl = this.router.url;
      if (currentUrl.includes('checkout')) {
        this.pageGuard.setNavigatedFromValidUrl(true);
      }
      this.location.back();
    }
  }
}