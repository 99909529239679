import { Module } from './module.model';

export class Plan {
  id!: number;
  articleNumber!: string;
  planType!: string;
  price!: number;
  originalPrice!: number;
  discount!: number;
  devicesCount!: number;
  workStationCount!: number;
  clientsCount!: number;
  modules!: Module[];
  additionalModules?: Module[];
  includedModules?: Module[];
  startDate!: Date;
  cloudVersion!: boolean;
  packageType!: string;
  monthlyCostSum?: number;
}
