import { Component, Input } from '@angular/core';
import { SharedModule } from '@oneqrew-partnertool/utils';

@Component({
  selector: 'lib-container',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent {
  @Input() isWide = false;  

  get class(): string {
    return this.isWide ? 'container container-wide' : 'container';
  }
}
