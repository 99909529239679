import { createAction, props } from '@ngrx/store';
import { Plan, Module } from '@oneqrew-partnertool/models';

export const setPlan = createAction(
  '[Plan] Set Plan',
  props<Plan>()
);

export const addAdditionalModule = createAction(
  '[Plan] Add Plan Additional Module',
  props<Module>()
);

export const removeAdditionalModule = createAction(
  '[Plan] Remove Plan Additional Module',
  props<Module>()
);
