import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectCustomer, selectPlan } from '../../../core/src';
import { take, tap } from 'rxjs';
import { Plan } from '../../../models/src';

@Injectable({
  providedIn: 'root'
})
export class NewCustomerPageGuard implements CanActivate {

  constructor(private router: Router, private store: Store) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const session = JSON.parse(sessionStorage.getItem('customer') as string);

    let plan: Plan = new Plan();
    this.store.select(selectPlan)
      .pipe(take(1))
      .subscribe(selectedPlan => {
        plan = selectedPlan
      });
    // Plan should be selected, customer data existing unless its orderpage
    if (plan.startDate && (session.customer.email || route.data['isOrderPage'])) {
      return true;
    }
    // Navigate to homepage if not accessed correctly
    this.router.navigate(['/']);
    return false;
  }
}
