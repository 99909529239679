import { Component, Input } from '@angular/core';
import { SharedModule } from '@oneqrew-partnertool/utils';

@Component({
  selector: 'lib-accordion',
  imports: [SharedModule],
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  standalone: true
})
export class AccordionComponent {
  @Input() title = '';
  isExpanded = false;
  class = 'accordion';

  changeClass(newClass: string): void {
    this.class = newClass;
  }

  toggleAccordion() {
    this.isExpanded = !this.isExpanded;
  }
}