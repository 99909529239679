import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { APP_CONFIG, AppConfig, environment } from '@oneqrew-partnertool/config';
import { ContainerComponent } from '@oneqrew-partnertool/container';
import { selectCustomer } from '@oneqrew-partnertool/core';
import { ContactDetails, ContactForm, Customer, CustomerPayload, SalesPartnerPayload } from '@oneqrew-partnertool/models';
import { PrimeNgModule } from '@oneqrew-partnertool/primeng';
import { SharedModule } from '@oneqrew-partnertool/utils';
import { RecaptchaComponent, RecaptchaModule } from 'ng-recaptcha-2';
import { Observable, catchError, throwError } from 'rxjs';
import * as contactData from '../../../../../shared-assets/data/pages/contact-page.json';
@Component({
  selector: 'lib-contact-form',
  standalone: true,
  imports: [SharedModule, PrimeNgModule, ContainerComponent, RecaptchaModule],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.scss'
})
export class ContactFormComponent implements OnInit {
  class = 'contact-form';
  customer!: Customer;
  contactForm!: FormGroup;
  maxMessageLength = 600;
  contactData = contactData;
  recaptchaSiteKey = environment.landingPage.recaptcha;
  sending = false;
  formMessage = '';
  submissionState = '';
  @ViewChild(RecaptchaComponent) recaptcha!: RecaptchaComponent;

  constructor(private fb: FormBuilder, @Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient, private store: Store) {}

  ngOnInit(): void {
    this.store.select(selectCustomer).subscribe((customer) => {
      this.customer = customer;
    });

    this.contactForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.pattern('^[0-9]+$')]],
      message: ['', [Validators.required, Validators.maxLength(this.maxMessageLength)]],
      terms: [false, Validators.requiredTrue],
      recaptcha: ['', Validators.required]
    });
  }

  get messageLength(): number {
    return this.contactForm.get('message')?.value?.length || 0;
  }

  onSubmit() {
    this.sending = true;

    const contactDetails: ContactDetails = {
      id: this.customer.contactId,
      bsNumber: this.customer.bsNumber,
      salutation: this.customer.salutation,
      salutationOpener: this.customer.salutationOpener,
      firstName: this.customer.firstName,
      lastName: this.customer.lastName,
      phoneNr: String(this.customer.phoneNr),
      mobilePhoneNr: this.customer.mobilePhoneNr,
      email: this.customer.email,
      companyName: this.customer.company,
      street: this.customer.street,
      addressExtra: this.customer.addressTwo,
      postalCode: this.customer.postCode,
      city: this.customer.city
    };

    const salesPartner: SalesPartnerPayload = {
      ...this.customer.salesPartner,
      contactDetails: this.customer.salesPartner.contactDetails
    };

    const customer: CustomerPayload = {
      id: this.customer.id,
      knr: this.customer.knr,
      legacyProductName: this.customer.legacyProductName,
      hasAcceptedAgb: true,
      hasAcceptedPrivacyAgreement: true,
      contactDetails,
      salesPartner
    };

    if (this.contactForm.valid) {
      const payload: ContactForm = {
        message: this.contactForm.value.message,
        email: this.contactForm.value.email,
        phoneNumber: this.contactForm.value.phone,
        name: this.contactForm.value.name,
        customer
      };

      this.sendContactForm(payload).subscribe({
        next: (response) => {
          console.log('Form submitted successfully. Response:', response);
        },
        error: (error) => {
          this.sending = false;
          this.submissionState = 'sent';
          this.formMessage =
            'Beim Senden Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.';
          console.error('Error occurred while submitting form. Details:', error);
        },
        complete: () => {
          this.sending = false;
          this.submissionState = 'sent';
          this.formMessage = 'Das Formular wurde erfolgreich gesendet.';
          this.contactForm.reset();
          this.recaptcha.reset();
        }
      });
    } else {
      this.sending = false;
      console.error('Form is invalid');
    }
  }

  sendContactForm(contactForm: ContactForm): Observable<ContactForm> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };

    return this.http.post<ContactForm>(`${this.config.landingPage.apiURL}/contact-form/`, contactForm, httpOptions).pipe(
      catchError((error) => {
        console.error('Error occurred:', error);
        return throwError(() => error);
      })
    );
  }

  resolved(captchaResponse: string | null) {
    if (captchaResponse) {
      this.contactForm.get('recaptcha')?.setValue(captchaResponse);
    } else {
      console.warn('Captcha resolution returned null');
    }
  }
}
