import { createReducer, on } from '@ngrx/store';
import { setFetchedPlans } from './subscription-plans.action';
import { Plan } from '@oneqrew-partnertool/models';

export interface PlansState {
  fetchedPlans: Plan[];
}

const initState: PlansState = {
  fetchedPlans: []
};

export const subscriptionPlansReducer = createReducer(
  initState,
  on(setFetchedPlans, (state, { plans }) => {
    return {
      ...state,
      fetchedPlans: plans
    };
  })
);
