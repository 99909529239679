/**
 * BlueSolution Subscription Management API
 * Last changed: 2024-11-18T10:02:49+00:00
 *
 * OpenAPI spec version: 1.0.9
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { OneqrewModule } from '../model/oneqrewModule';
import { OneqrewProductPackage } from '../model/oneqrewProductPackage';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OneqrewService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Finds Products by ID
     * Returns Products based on ID
     * @param id ID of the Product
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByIdOneqrewProductPackage(id: number, observe?: 'body', reportProgress?: boolean): Observable<OneqrewProductPackage>;
    public getByIdOneqrewProductPackage(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OneqrewProductPackage>>;
    public getByIdOneqrewProductPackage(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OneqrewProductPackage>>;
    public getByIdOneqrewProductPackage(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getByIdOneqrewProductPackage.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OneqrewProductPackage>('get',`${this.basePath}/products/${encodeURIComponent(String(id))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Finds Modules by ProductID
     * Returns Modules based on ProductID
     * @param productid Product ID of the Module
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByProductIdOneqrewModule(productid: number, observe?: 'body', reportProgress?: boolean): Observable<OneqrewModule>;
    public getByProductIdOneqrewModule(productid: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OneqrewModule>>;
    public getByProductIdOneqrewModule(productid: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OneqrewModule>>;
    public getByProductIdOneqrewModule(productid: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productid === null || productid === undefined) {
            throw new Error('Required parameter productid was null or undefined when calling getByProductIdOneqrewModule.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OneqrewModule>('get',`${this.basePath}/modules/${encodeURIComponent(String(productid))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all modules that are marked as extra
     * 
     * @param maxResults Number of results that should be returned.
     * @param page Get results from this Page
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExtraModulesOneqrewModule(maxResults: number, page: number, observe?: 'body', reportProgress?: boolean): Observable<Array<OneqrewModule>>;
    public getExtraModulesOneqrewModule(maxResults: number, page: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OneqrewModule>>>;
    public getExtraModulesOneqrewModule(maxResults: number, page: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OneqrewModule>>>;
    public getExtraModulesOneqrewModule(maxResults: number, page: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (maxResults === null || maxResults === undefined) {
            throw new Error('Required parameter maxResults was null or undefined when calling getExtraModulesOneqrewModule.');
        }

        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getExtraModulesOneqrewModule.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (maxResults !== undefined && maxResults !== null) {
            queryParameters = queryParameters.set('maxResults', <any>maxResults);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<OneqrewModule>>('get',`${this.basePath}/modules/extras/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all modules that belong to a given package type.
     * Get all modules that belong to a given package type. [pro-s, pro-m, pro-l]
     * @param packageType [pro-s, pro-m, pro-l]
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModulesInProductPackageOneqrewModule(packageType: string, observe?: 'body', reportProgress?: boolean): Observable<Array<OneqrewModule>>;
    public getModulesInProductPackageOneqrewModule(packageType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OneqrewModule>>>;
    public getModulesInProductPackageOneqrewModule(packageType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OneqrewModule>>>;
    public getModulesInProductPackageOneqrewModule(packageType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (packageType === null || packageType === undefined) {
            throw new Error('Required parameter packageType was null or undefined when calling getModulesInProductPackageOneqrewModule.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<OneqrewModule>>('get',`${this.basePath}/modules/${encodeURIComponent(String(packageType))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get product package with all linked modules
     * Find product package with all modules by Package type. [pro-s,pro-m,pro-l]
     * @param packageType Get all products for a given package type small,medium,large  Valid values are S, M or L
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductByPackageTypeOneqrewProductPackage(packageType: string, observe?: 'body', reportProgress?: boolean): Observable<OneqrewProductPackage>;
    public getProductByPackageTypeOneqrewProductPackage(packageType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OneqrewProductPackage>>;
    public getProductByPackageTypeOneqrewProductPackage(packageType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OneqrewProductPackage>>;
    public getProductByPackageTypeOneqrewProductPackage(packageType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (packageType === null || packageType === undefined) {
            throw new Error('Required parameter packageType was null or undefined when calling getProductByPackageTypeOneqrewProductPackage.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OneqrewProductPackage>('get',`${this.basePath}/products/${encodeURIComponent(String(packageType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
