import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ContainerComponent } from '@oneqrew-partnertool/container';
import { SharedModule } from '@oneqrew-partnertool/utils';
import * as footerData from '../../../../../shared-assets/data/shared/footer.json';
@Component({
  selector: 'lib-footer',
  standalone: true,
  imports: [RouterLink, SharedModule, ContainerComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  class = 'footer';
  footerData = footerData;

  changeClass(newClass: string): void {
    this.class = newClass;
  }
}
