<section [ngClass]="class">
  <lib-container>
    <div class="success">
      <!-- <div class="success__progress">
        <div class="progress-step progress-step--completed">
          <div class="progress-circle progress-circle--completed">
            <i class="pi pi-check"></i>
          </div>
          <div class="progress-text progress-text--completed">
            <p class="progress-label">Anfrage gesendet</p>
            <p class="progress-date text-light-orange">{{ dateSent }}</p>
          </div>
        </div>
        <div class="progress-step">
          <div class="progress-circle progress-circle--current">
            <span>2</span>
          </div>
          <div class="progress-text progress-text--current">
            <p class="progress-label">Umstellung Ihres Vertrags</p>
          </div>
        </div>
        <div class="progress-step">
          <div class="progress-circle progress-circle--upcoming">3</div>
          <div class="progress-text">
            <p class="progress-label">Download & Lizenzschlüssel via E-Mail</p>
          </div>
        </div>
      </div> -->

      <div class="text-center pt-5">
        <img src="assets/img/icons/success-icon.svg" alt="Success" />
        <h2>
          Erfolgreich
          <span class="text-header-underlined">
            <ng-container *ngIf="isNewPlanPage; else notNewPlan">bestellt</ng-container> 
            <ng-template #notNewPlan>Wechsel beauftragt</ng-template>
          </span>
        </h2>
        <div class="success__message">
          <p class="mb-5">
            Ihr Antrag wurde erfolgreich eingereicht und in Kürze per E-Mail verschickt. Sobald die Bestätigung nach unserer Prüfung erfolgt
            ist, erhalten Sie eine E-Mail mit einem Download Link und dem zugehörigen Lizenzschlüssel sowie allen Details zu Ihrem neuen
            Tarif.
          </p>
          <p>
            Wenn Sie in der Zwischenzeit Fragen haben, stehen Ihnen unsere Kundenberater gerne zur Verfügung. Wir freuen uns darauf, Ihnen
            bald Ihren neuen Tarif zur Verfügung zu stellen!
          </p>
        </div>
      </div>
    </div>
  </lib-container>
</section>
