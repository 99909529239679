<lib-container>
  <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" autocomplete="on">
    <div [ngClass]="class">
      <div [ngClass]="class + '__header'">
        <p>{{ contactData.contactForm['contactTitle'] }}</p>
        <h2>{{ contactData.contactForm['contactSubtitle'] }}</h2>
      </div>
      <div class="grid smedium-text">
        <div class="col-12 lg:col-4">
          <div [ngClass]="class + '__field'">
            <label class="required" for="name">{{ contactData.contactForm['labelName'] }}</label>
            <input id="name" pInputText formControlName="name" />
            <span class="small-text block text-highlight"
              *ngIf="contactForm.get('name')?.invalid && contactForm.get('name')?.dirty">
              Bitte geben Sie Ihren Namen ein
            </span>
          </div>
        </div>
        <div class="col-12 lg:col-4">
          <div [ngClass]="class + '__field'">
            <label class="required" for="email">{{ contactData.contactForm['labelEmail'] }}</label>
            <input id="email" type="email" pInputText formControlName="email" />
            <span class="small-text block text-highlight"
              *ngIf="contactForm.get('email')?.hasError('required') && contactForm.get('email')?.dirty">
              Bitte geben Sie Ihre E-Mail Adresse ein
            </span>
            <span class="small-text block text-highlight"
              *ngIf="contactForm.get('email')?.hasError('email') && contactForm.get('email')?.dirty">
              Bitte geben Sie Ihre korrekte E-Mail Adresse ein
            </span>
          </div>
        </div>
        <div class="col-12 lg:col-4">
          <div [ngClass]="class + '__field'">
            <label for="phone">{{ contactData.contactForm['labelPhone'] }}</label>
            <input id="phone" type="tel" pInputText formControlName="phone" />
          </div>
        </div>
        <div class="col-12">
          <div [ngClass]="class + '__field'">
            <label class="required" for="message">{{ contactData.contactForm['labelMessage'] }}</label>
            <textarea id="message" pInputTextarea rows="6" formControlName="message" maxlength="600"></textarea>
            <span class="align-self-end text-dark-grey">{{ messageLength }}/{{ maxMessageLength }}</span>
            <span class="small-text block text-highlight"
              *ngIf="contactForm.get('message')?.invalid && contactForm.get('message')?.dirty">
              Bitte geben Sie Ihre Nachricht ein
            </span>
          </div>
        </div>
        <div class="col-12">
          <label for="terms" class="{{ class + '__checkbox required' }}">
            <p-checkbox formControlName="terms" binary="true" inputId="terms"></p-checkbox>
            <span class="{{ class + '__checkbox-text ml-3' }}"
              [innerHTML]="contactData.contactForm['consentText']"></span>
          </label>

          <p class="text-dark-grey-2 mb-4">{{ contactData.contactForm['hint'] }}</p>

          <div class="mb-6 flex">
            <re-captcha (resolved)="resolved($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
            <span class="required"></span>
          </div>

          <div class="flex align-content-center">
            <button pButton type="submit" [disabled]="contactForm.invalid || sending">
              <span class="mr-3">{{ contactData.contactForm['submitButtonText'] }}</span>
              <img src="assets/img/icons/send.svg" alt="{{ contactData.contactForm['submitButtonIconAlt'] }}"
                class="custom-icon" />
            </button>

            <div *ngIf="sending" class="{{ class + '__spinner' }}">
              <p><i class="pi pi-spin pi-spinner"></i></p>
            </div>
          </div>

          <p *ngIf="formMessage"
            [ngClass]="{ 'text-red': submissionState === 'error', 'text-highlight': submissionState === 'sent' }"
            class="small-text mt-2">
            {{ formMessage }}
          </p>
        </div>
      </div>
    </div>
  </form>
</lib-container>