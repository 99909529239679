import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Customer, SalesPartner } from '@oneqrew-partnertool/models';
import { PrimeNgModule } from '@oneqrew-partnertool/primeng';
import { TagComponent } from '@oneqrew-partnertool/tag';
import { SharedModule } from '@oneqrew-partnertool/utils';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectCustomer } from '../../../../../../landingpage/core/src';
import { take } from 'rxjs';

@Component({
  selector: 'lib-order-customer-info',
  standalone: true,
  imports: [SharedModule, PrimeNgModule, TagComponent],
  templateUrl: './order-customer-info.component.html',
  styleUrls: ['./order-customer-info.component.scss']
})
export class OrderCustomerInfoComponent implements OnInit {
  @Input() tag: boolean = false;
  @Input() tagSize: string = '';
  @Input() isNewCustomer = false;
  @Output() contactForm$ = new EventEmitter<FormGroup>();
  salesPartners: SalesPartner[] = []
  class = 'order-customer-info';
  contactForm!: FormGroup;
  salesPartnerSelect: { label: string | undefined; value: number | undefined }[] = [];
  selectedSalesPartner: number | undefined;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private store: Store) {
    this.contactForm = this.fb.group({
      salesPartner: [],
      bsNumber: []
    });
  }

  ngOnInit(): void {
    this.route.data.subscribe((data: { [key: string]: any }) => {
      this.salesPartners = data['salesPartners'];
      this.salesPartnerSelect = this.getSalesPartnerSelect(this.salesPartners)
    })

    this.store
      .select(selectCustomer)
      .pipe(take(1))
      .subscribe((storedCustomer: Customer) => {
        this.initializeForm(storedCustomer);
        const blueSolutionsNr = "1030372";
        const defaultSalesPartnerNr = storedCustomer.salesPartner?.id ?? this.salesPartners.find(s => (s.knr == blueSolutionsNr))?.id;
        this.selectedSalesPartner = this.salesPartnerSelect.find(s => (s.value == defaultSalesPartnerNr))?.value
      });
    this.contactForm$.emit(this.contactForm);
  }

  private getSalesPartnerSelect(salesPartners: SalesPartner[]): { label: string, value: number }[] {
    const sortedSalePartners = salesPartners.filter((s) => s.name)
      .sort((a: SalesPartner, b: SalesPartner) => {
        const isANumber = /^\d/.test(a.name!!);
        const isBNumber = /^\d/.test(b.name!!);

        // Numbers first
        if (isANumber && !isBNumber) return -1;
        if (!isANumber && isBNumber) return 1;

        // If both are numbers or both are not, sort alphabetically
        return a.name!!.localeCompare(b.name!!, undefined, { numeric: true });
      });
    return sortedSalePartners.map(s => ({ label: s.name!!, value: s.id!! }));
  }


  getErrorMessage(field: string, label: string): string {
    const control = this.contactForm.get(field);

    if (control?.hasError('required')) {
      return `Bitte ${label} eingeben.`;
    }

    if (control?.hasError('minlength')) {
      return `${label} must be at least 2 characters long.`;
    }

    return '';
  }


  private initializeForm(storedCustomer: Customer): void {
    this.contactForm = this.fb.group({
      salesPartner: [storedCustomer.salesPartner?.id],
      bsNumber: [storedCustomer.bsNumber]
    })

  }
}
