import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'perMonth'
})
export class PerMonthPipe implements PipeTransform {

  transform(value: string | number): string {
    return `${value} / Monat`;
  }

}
