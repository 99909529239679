/**
 * BlueSolution Subscription Management API
 * Last changed: 2025-03-05T10:54:50+00:00
 *
 * OpenAPI spec version: 1.0.13
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Customer Subscription Order Contract
 */
export interface SubscriptionOrderContract { 
    /**
     * Blob filename
     */
    filename: string;
    /**
     * Creation Date of the Contract File
     */
    creationDate: string;
    /**
     * Blob file url
     */
    url?: string;
    id?: number;
}