import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { addAdditionalModule, removeAdditionalModule } from '@oneqrew-partnertool/core';
import { selectPlan, selectFetchedPlans } from '@oneqrew-partnertool/core';
import { ContainerComponent } from '@oneqrew-partnertool/container';
import { ModuleCardComponent } from '@oneqrew-partnertool/module-card';
import { Module, Plan } from '@oneqrew-partnertool/models';
import { SharedModule } from '@oneqrew-partnertool/utils';
import { OneqrewService } from '@oneqrew-partnertool/api';
@Component({
  selector: 'lib-module-addons',
  standalone: true,
  imports: [SharedModule, ContainerComponent, ModuleCardComponent],
  templateUrl: './module-addons.component.html',
  styleUrls: ['./module-addons.component.scss']
})
export class ModuleAddonsComponent implements OnInit {
  class = 'module-addons';
  modulesData: any = {
    moduleAddonTitle: 'Zusätzliche Module',
    modules: []
  };
  plan$!: Observable<Plan>;
  tagList: string[] = [];
  sortedModules: any[] = [];
  baseTag = 'in L-Paket enthalten';

  constructor(private store: Store, private cdr: ChangeDetectorRef, private oneqrewService: OneqrewService) {}

  ngOnInit(): void {
    this.plan$ = this.store.select(selectPlan);
    this.cdr.detectChanges(); // Trigger change detection manually to avoid the error
    this.getExtraModulesOneqrewModule();
  }

  getExtraModulesOneqrewModule(): void {
    this.oneqrewService.getExtraModulesOneqrewModule(100, 0).subscribe({
      next: (modules) => {
        this.modulesData.modules = modules.map((module) => {
          return {
            id: module.id,
            name: module.name,
            pricePerMonth: module.monthlyPrice,
            isExtra: module.isExtra
          };
        });

        this.generateTags();
      },
      error: (error) => {
        console.error('Error fetching modules:', error);
      }
    });
  }

  generateTags(): void {
    const tagDictionary: { [name: string]: string } = {};
    const moduleNames = this.modulesData.modules.map((module: any) => module.name.trim());

    this.store
      .select(selectFetchedPlans)
      .pipe(take(1))
      .subscribe((fetchedPlans) => {
        if (!fetchedPlans || fetchedPlans.length === 0) {
          console.warn('Fetched Plans are empty. Ensure the store is updated before this component initializes.');
        } else {
          const selectedPlan = fetchedPlans[1];
          if (selectedPlan && selectedPlan.additionalModules) {
            selectedPlan.additionalModules.forEach((module: any) => {
              const normalizedModuleName = module.name.trim();

              if (moduleNames.includes(normalizedModuleName)) {
                tagDictionary[normalizedModuleName] = this.baseTag;
              }
            });
          }
        }
      });

    // Map tagList based on the dictionary and the order of modulesData.modules
    this.tagList = this.modulesData.modules.map((module: any) => tagDictionary[module.name.trim()] || '');
  }

  isActive(plan: Plan, moduleName: string): boolean {
    return plan.additionalModules?.some((module) => module.name === moduleName) || false;
  }

  handleModuleToggled(event: { id: number; name: string; pricePerMonth: number; isActive: boolean }) {
    const module = this.modulesData.modules.find((mod: { id: number }) => mod.id === event.id);

    if (!module) {
      console.warn(`Module with ID ${event.id} not found in the fetched modules.`);
      return;
    }

    if (event.isActive) {
      this.addModuleToStore({
        id: module.id,
        name: module.name,
        price: module.pricePerMonth
      });
    } else {
      this.removeModuleFromStore({
        id: module.id,
        name: module.name,
        price: module.pricePerMonth
      });
    }
    this.cdr.detectChanges();
  }

  addModuleToStore(module: Module) {
    this.store.dispatch(addAdditionalModule(module));
  }

  removeModuleFromStore(module: Module) {
    this.store.dispatch(removeAdditionalModule(module));
  }
}
