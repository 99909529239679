import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { ContactFormComponent } from '@oneqrew-partnertool/contact-form';
import { selectCustomer } from '@oneqrew-partnertool/core';
import { Customer } from '@oneqrew-partnertool/models';
@Component({
  selector: 'app-contact-page',
  standalone: true,
  imports: [CommonModule, ContactFormComponent],
  templateUrl: './contact-page.component.html'
})
export class ContactPageComponent implements OnInit {
  pageTitle: string = 'Umstellung shp - Kontakt';
  customer!: Customer;

  constructor(private titleService: Title, private store: Store) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.pageTitle);

    this.store.select(selectCustomer).subscribe((customer) => {
      this.customer = customer;
    });
  }
}
