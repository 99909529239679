import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PlanDataService } from '@oneqrew-partnertool/utils';
import { selectFetchedPlans, setFetchedPlans } from '@oneqrew-partnertool/core';
import { Observable, of } from 'rxjs';
import { tap, map, take, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
@Injectable({ providedIn: 'root' })
export class InitailPlansResolver implements Resolve<any> {
  constructor(private planDataService: PlanDataService, private store: Store) {}

  resolve(): Observable<any> {
    return this.store
      .select(selectFetchedPlans)
      .pipe(take(1))
      .pipe(switchMap((fetchedPlans) => {
        if (!fetchedPlans || fetchedPlans.length === 0) {
          return this.planDataService.getInitialPlans().pipe(
            tap((fetchedPlans) => {
              this.store.dispatch(setFetchedPlans({ plans: fetchedPlans }));
            }),
            map((fetchedPlans) => fetchedPlans)
          );
        } else {
          return of(fetchedPlans)
        }
      }));
  }
}
