import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CustomDateFormatPipe, EuroCurrencyPipe, PerMonthPipe, PluralizePipe, BasicModuleTextPipe } from '@oneqrew-partnertool/pipes';

@NgModule({
  declarations: [EuroCurrencyPipe, PerMonthPipe, CustomDateFormatPipe, PluralizePipe, BasicModuleTextPipe],
  imports: [CommonModule, ReactiveFormsModule, RouterModule],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    EuroCurrencyPipe,
    PerMonthPipe,
    CustomDateFormatPipe,
    PluralizePipe,
    BasicModuleTextPipe
  ]
})
export class SharedModule {}
