import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'basicModuleText'
})
export class BasicModuleTextPipe implements PipeTransform {
  transform(packageType: string): string {
    return packageType === 'smarthandwerk pro M-Paket' ? 'inkl. 1. Arbeitsplatz' : 'inkl. 1. Arbeitsplatz und 2 mobile Geräte';
  }
}
