import { Component, OnInit, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PrimeNgModule } from '@oneqrew-partnertool/primeng';
import { SharedModule } from '@oneqrew-partnertool/utils';
import { TagComponent } from '@oneqrew-partnertool/tag';
import { Customer } from '@oneqrew-partnertool/models';
import * as subscriptionData from '../../../../../shared-assets/data/pages/front-page.json';

@Component({
  selector: 'lib-subscription-overview',
  standalone: true,
  imports: [SharedModule, PrimeNgModule, TagComponent],
  templateUrl: './subscription-overview.component.html',
  styleUrl: './subscription-overview.component.scss'
})
export class SubscriptionOverviewComponent implements OnInit {
  class = 'subscription';
  subscriptionData = subscriptionData;
  customer$!: Observable<Customer>;
  @Input() customer!: Customer;

  constructor() {}

  ngOnInit(): void {
    this.customer$ = of(this.customer);
  }
}
