import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { Customer } from '@oneqrew-partnertool/models';

@Component({
  selector: 'app-pdf-viewer',
  imports: [CommonModule],
  standalone: true,
  template: `
    <div>
      <p *ngIf="!pdfUrl">Die PDF-Datei kann nicht gefunden werden. Bitte wenden Sie sich an Ihren Administrator.</p>
      <iframe *ngIf="pdfUrl" [src]="pdfUrl" width="100%" height="800px" frameborder="0"></iframe>
    </div>
  `
})
export class PdfViewerPageComponent implements OnInit {
  pdfUrl: SafeResourceUrl | null = null;
  customer!: Customer;

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const filename = params.get('filename');
      if (filename) {
        // Full Azure Blob Storage URL
        const blobStorageUrl = `https://stoaccdatabssdeuwec428.blob.core.windows.net/generated-pdfs/order-contracts/${filename}`;

        // Trust and sanitize the URL
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobStorageUrl);
      }
    });
  }
}
