/**
 * BlueSolution Subscription Management API
 * Last changed: 2025-03-05T10:54:50+00:00
 *
 * OpenAPI spec version: 1.0.13
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CustomerContactDetails } from '../model/customerContactDetails';
import { CustomerContactForm } from '../model/customerContactForm';
import { CustomerCustomer } from '../model/customerCustomer';
import { CustomerLegacySubscription } from '../model/customerLegacySubscription';
import { CustomerOneqrewSubscription } from '../model/customerOneqrewSubscription';
import { CustomerSalesPartner } from '../model/customerSalesPartner';
import { CustomerSubscriptionOrder } from '../model/customerSubscriptionOrder';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CustomerService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Send email with customer message
     * Send email with customer message
     * @param body The object data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contactCustomerContactForm(body?: CustomerContactForm, observe?: 'body', reportProgress?: boolean): Observable<CustomerContactForm>;
    public contactCustomerContactForm(body?: CustomerContactForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerContactForm>>;
    public contactCustomerContactForm(body?: CustomerContactForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerContactForm>>;
    public contactCustomerContactForm(body?: CustomerContactForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerContactForm>('post',`${this.basePath}/contact-form/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Contactdetails
     * Creates a new Contactdetails object
     * @param body The object data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCustomerContactDetails(body?: CustomerContactDetails, observe?: 'body', reportProgress?: boolean): Observable<CustomerContactDetails>;
    public createCustomerContactDetails(body?: CustomerContactDetails, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerContactDetails>>;
    public createCustomerContactDetails(body?: CustomerContactDetails, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerContactDetails>>;
    public createCustomerContactDetails(body?: CustomerContactDetails, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerContactDetails>('post',`${this.basePath}/contactdetails/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create OneqrewSubscription
     * Creates a new OneqrewSubscription object
     * @param uniqueKey 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCustomerOneqrewSubscription(uniqueKey: string, body?: CustomerOneqrewSubscription, observe?: 'body', reportProgress?: boolean): Observable<CustomerOneqrewSubscription>;
    public createCustomerOneqrewSubscription(uniqueKey: string, body?: CustomerOneqrewSubscription, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerOneqrewSubscription>>;
    public createCustomerOneqrewSubscription(uniqueKey: string, body?: CustomerOneqrewSubscription, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerOneqrewSubscription>>;
    public createCustomerOneqrewSubscription(uniqueKey: string, body?: CustomerOneqrewSubscription, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uniqueKey === null || uniqueKey === undefined) {
            throw new Error('Required parameter uniqueKey was null or undefined when calling createCustomerOneqrewSubscription.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerOneqrewSubscription>('post',`${this.basePath}/oneqrew-subscriptions/${encodeURIComponent(String(uniqueKey))}/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create new Customer Subscription Order
     * Create new Customer Subscription Order and create other entities (Customer, ContactDetails, OrderContract)
     * @param body 
     * @param timezone 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCustomerSubscriptionOrder(body?: CustomerSubscriptionOrder, timezone?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerSubscriptionOrder>;
    public createCustomerSubscriptionOrder(body?: CustomerSubscriptionOrder, timezone?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerSubscriptionOrder>>;
    public createCustomerSubscriptionOrder(body?: CustomerSubscriptionOrder, timezone?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerSubscriptionOrder>>;
    public createCustomerSubscriptionOrder(body?: CustomerSubscriptionOrder, timezone?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (timezone !== undefined && timezone !== null) {
            queryParameters = queryParameters.set('timezone', <any>timezone);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerSubscriptionOrder>('post',`${this.basePath}/subscription-order/`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all LegacySubscriptions for a given unique key
     * Returns all LegacySubscriptions from the system by searching for the user that owns the UniqueKey and returning their subscriptions
     * @param maxResults Number of results that should be returned.
     * @param page Get results from this Page
     * @param uniqueKey Example Key: 6a5e-b75a-0e59-1780.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllByUniqueKeyCustomerLegacySubscription(maxResults: number, page: number, uniqueKey: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CustomerLegacySubscription>>;
    public getAllByUniqueKeyCustomerLegacySubscription(maxResults: number, page: number, uniqueKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CustomerLegacySubscription>>>;
    public getAllByUniqueKeyCustomerLegacySubscription(maxResults: number, page: number, uniqueKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CustomerLegacySubscription>>>;
    public getAllByUniqueKeyCustomerLegacySubscription(maxResults: number, page: number, uniqueKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (maxResults === null || maxResults === undefined) {
            throw new Error('Required parameter maxResults was null or undefined when calling getAllByUniqueKeyCustomerLegacySubscription.');
        }

        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getAllByUniqueKeyCustomerLegacySubscription.');
        }

        if (uniqueKey === null || uniqueKey === undefined) {
            throw new Error('Required parameter uniqueKey was null or undefined when calling getAllByUniqueKeyCustomerLegacySubscription.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (maxResults !== undefined && maxResults !== null) {
            queryParameters = queryParameters.set('maxResults', <any>maxResults);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CustomerLegacySubscription>>('get',`${this.basePath}/legacy-subscriptions/${encodeURIComponent(String(uniqueKey))}/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all LegacySubscriptions
     * Returns all LegacySubscriptions from the system that the user has access to.
     * @param maxResults Number of results that should be returned.
     * @param page Get results from this Page
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCustomerLegacySubscription(maxResults?: number, page?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CustomerLegacySubscription>>;
    public getAllCustomerLegacySubscription(maxResults?: number, page?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CustomerLegacySubscription>>>;
    public getAllCustomerLegacySubscription(maxResults?: number, page?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CustomerLegacySubscription>>>;
    public getAllCustomerLegacySubscription(maxResults?: number, page?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (maxResults !== undefined && maxResults !== null) {
            queryParameters = queryParameters.set('maxResults', <any>maxResults);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CustomerLegacySubscription>>('get',`${this.basePath}/legacy-subscriptions/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all SalesPartner
     * Get all SalesPartner
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCustomerSalesPartner(observe?: 'body', reportProgress?: boolean): Observable<Array<CustomerSalesPartner>>;
    public getAllCustomerSalesPartner(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CustomerSalesPartner>>>;
    public getAllCustomerSalesPartner(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CustomerSalesPartner>>>;
    public getAllCustomerSalesPartner(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CustomerSalesPartner>>('get',`${this.basePath}/sales-partners/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Finds Customers by ID
     * Returns Customers based on ID
     * @param id ID of the Customer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByIdCustomerCustomer(id: number, observe?: 'body', reportProgress?: boolean): Observable<CustomerCustomer>;
    public getByIdCustomerCustomer(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerCustomer>>;
    public getByIdCustomerCustomer(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerCustomer>>;
    public getByIdCustomerCustomer(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getByIdCustomerCustomer.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CustomerCustomer>('get',`${this.basePath}/customers/${encodeURIComponent(String(id))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return all necessary customer info by uniqueKey
     * 
     * @param customerCode The customer unique-key as query param
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerByUniqueKeyCustomerCustomer(customerCode: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerCustomer>;
    public getCustomerByUniqueKeyCustomerCustomer(customerCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerCustomer>>;
    public getCustomerByUniqueKeyCustomerCustomer(customerCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerCustomer>>;
    public getCustomerByUniqueKeyCustomerCustomer(customerCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerCode === null || customerCode === undefined) {
            throw new Error('Required parameter customerCode was null or undefined when calling getCustomerByUniqueKeyCustomerCustomer.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerCode !== undefined && customerCode !== null) {
            queryParameters = queryParameters.set('customerCode', <any>customerCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CustomerCustomer>('get',`${this.basePath}/customer/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uniqueKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSubscriptionByUniqueKeyCustomerOneqrewSubscription(uniqueKey: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CustomerOneqrewSubscription>>;
    public getSubscriptionByUniqueKeyCustomerOneqrewSubscription(uniqueKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CustomerOneqrewSubscription>>>;
    public getSubscriptionByUniqueKeyCustomerOneqrewSubscription(uniqueKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CustomerOneqrewSubscription>>>;
    public getSubscriptionByUniqueKeyCustomerOneqrewSubscription(uniqueKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uniqueKey === null || uniqueKey === undefined) {
            throw new Error('Required parameter uniqueKey was null or undefined when calling getSubscriptionByUniqueKeyCustomerOneqrewSubscription.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CustomerOneqrewSubscription>>('get',`${this.basePath}/oneqrew-subscription/${encodeURIComponent(String(uniqueKey))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uniqueKey 
     * @param body 
     * @param timezone 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orderSubscriptionChangeCustomerSubscriptionOrder(uniqueKey: string, body?: CustomerSubscriptionOrder, timezone?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerSubscriptionOrder>;
    public orderSubscriptionChangeCustomerSubscriptionOrder(uniqueKey: string, body?: CustomerSubscriptionOrder, timezone?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerSubscriptionOrder>>;
    public orderSubscriptionChangeCustomerSubscriptionOrder(uniqueKey: string, body?: CustomerSubscriptionOrder, timezone?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerSubscriptionOrder>>;
    public orderSubscriptionChangeCustomerSubscriptionOrder(uniqueKey: string, body?: CustomerSubscriptionOrder, timezone?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uniqueKey === null || uniqueKey === undefined) {
            throw new Error('Required parameter uniqueKey was null or undefined when calling orderSubscriptionChangeCustomerSubscriptionOrder.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (timezone !== undefined && timezone !== null) {
            queryParameters = queryParameters.set('timezone', <any>timezone);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerSubscriptionOrder>('post',`${this.basePath}/subscription-order/${encodeURIComponent(String(uniqueKey))}/`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the Contactdetails
     * Updates an existing Contactdetails
     * @param id Id of the 9500 that should be updated.
     * @param body Body containing the modified data.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCustomerContactDetails(id: number, body?: CustomerContactDetails, observe?: 'body', reportProgress?: boolean): Observable<CustomerContactDetails>;
    public updateCustomerContactDetails(id: number, body?: CustomerContactDetails, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerContactDetails>>;
    public updateCustomerContactDetails(id: number, body?: CustomerContactDetails, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerContactDetails>>;
    public updateCustomerContactDetails(id: number, body?: CustomerContactDetails, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateCustomerContactDetails.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerContactDetails>('put',`${this.basePath}/contactdetails/${encodeURIComponent(String(id))}/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the OneqrewSubscription
     * Updates an existing OneqrewSubscription
     * @param id Id of the 9519 that should be updated.
     * @param body Body containing the modified data.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCustomerOneqrewSubscription(id: number, body?: CustomerOneqrewSubscription, observe?: 'body', reportProgress?: boolean): Observable<CustomerOneqrewSubscription>;
    public updateCustomerOneqrewSubscription(id: number, body?: CustomerOneqrewSubscription, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerOneqrewSubscription>>;
    public updateCustomerOneqrewSubscription(id: number, body?: CustomerOneqrewSubscription, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerOneqrewSubscription>>;
    public updateCustomerOneqrewSubscription(id: number, body?: CustomerOneqrewSubscription, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateCustomerOneqrewSubscription.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerOneqrewSubscription>('put',`${this.basePath}/oneqrew-subscriptions/${encodeURIComponent(String(id))}/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
