import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedModule } from '@oneqrew-partnertool/utils';

@Component({
  selector: 'lib-module-card',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './module-card.component.html',
  styleUrls: ['./module-card.component.scss']
})
export class ModuleCardComponent {
  @Input() tag = '';
  @Input() name = '';
  @Input() id = 0;
  @Input() pricePerMonth = '';
  @Input() isActive = false;
  @Input() disabled = false;
  @Output() moduleToggled = new EventEmitter<{ id: number; name: string; pricePerMonth: number; isActive: boolean }>();

  class = 'module-card';

  changeClass(newClass: string): void {
    this.class = newClass;
  }

  onToggleActive(): void {
    this.isActive = !this.isActive;
    this.moduleToggled.emit({ id: this.id, name: this.name, pricePerMonth: parseFloat(this.pricePerMonth), isActive: this.isActive });
  }
}
